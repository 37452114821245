@font-face {
  font-family: "Indigo";
  src: url("~@gooddata/goodstrap/lib/./core/styles/fonts/Indigo-v2.eot");
  src: url("~@gooddata/goodstrap/lib/./core/styles/fonts/Indigo-v2.eot?#iefix") format("embedded-opentype"), url("~@gooddata/goodstrap/lib/./core/styles/fonts/Indigo-v2.woff") format("woff"), url("~@gooddata/goodstrap/lib/./core/styles/fonts/Indigo-v2.ttf") format("truetype"), url("~@gooddata/goodstrap/lib/./core/styles/fonts/Indigo-v2.svg#Indigo-v2") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]::before,
[class*=" icon-"]::before,
[class^="icon-"].icon-right::after,
[class*=" icon-"].icon-right::after {
  font-family: "Indigo";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  /* Better Font Rendering looks better in Chrome, corresponds to Sketch mockups */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  text-transform: none; }

[class^="icon-"].icon-right::before,
[class*=" icon-"].icon-right::before {
  content: "";
  display: none; }

.icon-trend-down::before,
.icon-trend-down.icon-right::after {
  content: "\e600"; }

.icon-trend-up::before,
.icon-trend-up.icon-right::after {
  content: "\e601"; }

.icon-user::before,
.icon-user.icon-right::after {
  content: "\e602"; }

.icon-ghost::before,
.icon-ghost.icon-right::after {
  content: "\e603"; }

.icon-redo::before,
.icon-redo.icon-right::after {
  content: "\e604"; }

.icon-undo::before,
.icon-undo.icon-right::after {
  content: "\e605"; }

.icon-pause::before,
.icon-pause.icon-right::after {
  content: "\e606"; }

.icon-users::before,
.icon-users.icon-right::after {
  content: "\e607"; }

.icon-cloud-rain::before, .icon-rain::before,
.icon-cloud-rain.icon-right::after,
.icon-right.icon-rain::after {
  content: "\e608"; }

.icon-calendar::before,
.icon-calendar.icon-right::after {
  content: "\e609"; }

.icon-circle-exclamation::before,
.icon-circle-exclamation.icon-right::after {
  content: "\e60a"; }

.icon-circle-question::before,
.icon-circle-question.icon-right::after {
  content: "\e60b"; }

.icon-dropright::before,
.icon-dropright.icon-right::after {
  content: "\e60c"; }

.icon-dropdown::before,
.icon-dropdown.icon-right::after {
  content: "\e60d"; }

.icon-question::before,
.icon-question.icon-right::after {
  content: "\e60e"; }

.icon-number::before,
.icon-number.icon-right::after {
  content: "\e60f"; }

.icon-navigateleft::before,
.icon-navigateleft.icon-right::after {
  content: "\e610"; }

.icon-navigateright::before,
.icon-navigateright.icon-right::after {
  content: "\e611"; }

.icon-navigatedown::before,
.icon-navigatedown.icon-right::after {
  content: "\e612"; }

.icon-navigateup::before,
.icon-navigateup.icon-right::after {
  content: "\e613"; }

.icon-pencil::before,
.icon-pencil.icon-right::after {
  content: "\e614"; }

.icon-magic::before,
.icon-magic.icon-right::after {
  content: "\e615"; }

.icon-cloud-upload::before, .icon-uploadcloud::before, .icon-upload::before,
.icon-cloud-upload.icon-right::after,
.icon-right.icon-uploadcloud::after,
.icon-right.icon-upload::after {
  content: "\e616"; }

.icon-lock::before,
.icon-lock.icon-right::after {
  content: "\e617"; }

.icon-unlock::before,
.icon-unlock.icon-right::after {
  content: "\e618"; }

.icon-settings::before, .icon-config::before,
.icon-settings.icon-right::after,
.icon-right.icon-config::after {
  content: "\e619"; }

.icon-trash::before,
.icon-trash.icon-right::after {
  content: "\e61a"; }

.icon-at-sign::before,
.icon-at-sign.icon-right::after {
  content: "\e61b"; }

.icon-envelope::before,
.icon-envelope.icon-right::after {
  content: "\e61c"; }

.icon-visible::before,
.icon-visible.icon-right::after {
  content: "\e61d"; }

.icon-invisible::before,
.icon-invisible.icon-right::after {
  content: "\e61e"; }

.icon-save::before,
.icon-save.icon-right::after {
  content: "\e61f"; }

.icon-printer::before,
.icon-printer.icon-right::after {
  content: "\e620"; }

.icon-file::before,
.icon-file.icon-right::after {
  content: "\e621"; }

.icon-folder::before,
.icon-folder.icon-right::after {
  content: "\e622"; }

.icon-enter::before,
.icon-enter.icon-right::after {
  content: "\e623"; }

.icon-earth::before,
.icon-earth.icon-right::after {
  content: "\e624"; }

.icon-bell::before,
.icon-bell.icon-right::after {
  content: "\e625"; }

.icon-horn::before,
.icon-horn.icon-right::after {
  content: "\e626"; }

.icon-sync::before,
.icon-sync.icon-right::after {
  content: "\e627"; }

.icon-clock::before,
.icon-clock.icon-right::after {
  content: "\e628"; }

.icon-timer::before,
.icon-timer.icon-right::after {
  content: "\e629"; }

.icon-download::before,
.icon-download.icon-right::after {
  content: "\e62a"; }

.icon-magnifier::before, .icon-search::before,
.icon-magnifier.icon-right::after,
.icon-right.icon-search::after {
  content: "\e62b"; }

.icon-checkmark::before,
.icon-checkmark.icon-right::after {
  content: "\e62c"; }

.icon-cross::before, .icon-clear::before,
.icon-cross.icon-right::after,
.icon-right.icon-clear::after {
  content: "\e62d"; }

.icon-chevron-up::before,
.icon-chevron-up.icon-right::after {
  content: "\e62e"; }

.icon-chevron-down::before,
.icon-chevron-down.icon-right::after {
  content: "\e62f"; }

.icon-chevron-left::before,
.icon-chevron-left.icon-right::after {
  content: "\e630"; }

.icon-chevron-right::before,
.icon-chevron-right.icon-right::after {
  content: "\e631"; }

.icon-arrow-up::before,
.icon-arrow-up.icon-right::after {
  content: "\e632"; }

.icon-arrow-down::before,
.icon-arrow-down.icon-right::after {
  content: "\e633"; }

.icon-arrow-left::before,
.icon-arrow-left.icon-right::after {
  content: "\e634"; }

.icon-arrow-right::before,
.icon-arrow-right.icon-right::after {
  content: "\e635"; }

.icon-tab::before,
.icon-tab.icon-right::after {
  content: "\e636"; }

.icon-warning::before,
.icon-warning.icon-right::after {
  content: "\e637"; }

.icon-circle-checkmark::before,
.icon-circle-checkmark.icon-right::after {
  content: "\e638"; }

.icon-circle-cross::before,
.icon-circle-cross.icon-right::after {
  content: "\e639"; }

.icon-circle-plus::before,
.icon-circle-plus.icon-right::after {
  content: "\e63a"; }

.icon-circle-minus::before,
.icon-circle-minus.icon-right::after {
  content: "\e63b"; }

.icon-filter::before,
.icon-filter.icon-right::after {
  content: "\e63c"; }

.icon-sharp::before,
.icon-sharp.icon-right::after {
  content: "\e63d"; }

.icon-money::before,
.icon-money.icon-right::after {
  content: "\e63e"; }

.icon-percent::before,
.icon-percent.icon-right::after {
  content: "\e63f"; }

.icon-plus::before, .icon-add::before,
.icon-plus.icon-right::after,
.icon-right.icon-add::after {
  content: "\e640"; }

.icon-A::before,
.icon-A.icon-right::after {
  content: "\e641"; }

.icon-copyright::before,
.icon-copyright.icon-right::after {
  content: "\e642"; }

/**
 * FixedDataTable v0.7.17 
 *
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 */
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableCellGroupLayout
 */
.fixedDataTableCellGroupLayout_cellGroup {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  white-space: nowrap; }

.fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main {
  display: inline-block;
  vertical-align: top;
  white-space: normal; }

.fixedDataTableCellGroupLayout_cellGroupWrapper {
  position: absolute;
  top: 0; }

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableCellLayout
 */
.fixedDataTableCellLayout_main {
  border-right-style: solid;
  border-right-width: 1px;
  border-width: 0 1px 0 0;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  position: absolute;
  white-space: normal; }

.fixedDataTableCellLayout_lastChild {
  border-width: 0 1px 1px 0; }

.fixedDataTableCellLayout_alignRight {
  text-align: right; }

.fixedDataTableCellLayout_alignCenter {
  text-align: center; }

.fixedDataTableCellLayout_wrap1 {
  display: table; }

.fixedDataTableCellLayout_wrap2 {
  display: table-row; }

.fixedDataTableCellLayout_wrap3 {
  display: table-cell;
  vertical-align: middle; }

.fixedDataTableCellLayout_columnResizerContainer {
  position: absolute;
  right: 0px;
  width: 6px;
  z-index: 1; }

.fixedDataTableCellLayout_columnResizerContainer:hover {
  cursor: ew-resize; }

.fixedDataTableCellLayout_columnResizerContainer:hover .fixedDataTableCellLayout_columnResizerKnob {
  visibility: visible; }

.fixedDataTableCellLayout_columnResizerKnob {
  position: absolute;
  right: 0px;
  visibility: hidden;
  width: 4px; }

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableColumnResizerLineLayout
 */
.fixedDataTableColumnResizerLineLayout_mouseArea {
  cursor: ew-resize;
  position: absolute;
  right: -5px;
  width: 12px; }

.fixedDataTableColumnResizerLineLayout_main {
  border-right-style: solid;
  border-right-width: 1px;
  box-sizing: border-box;
  position: absolute;
  z-index: 10; }

body[dir="rtl"] .fixedDataTableColumnResizerLineLayout_main {
  /* the resizer line is in the wrong position in RTL with no easy fix.
   * Disabling is more useful than displaying it.
   * #167 (github) should look into this and come up with a permanent fix.
   */
  display: none !important; }

.fixedDataTableColumnResizerLineLayout_hiddenElem {
  display: none !important; }

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableLayout
 */
.fixedDataTableLayout_main {
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative; }

.fixedDataTableLayout_header,
.fixedDataTableLayout_hasBottomBorder {
  border-bottom-style: solid;
  border-bottom-width: 1px; }

.fixedDataTableLayout_footer .public_fixedDataTableCell_main {
  border-top-style: solid;
  border-top-width: 1px; }

.fixedDataTableLayout_topShadow,
.fixedDataTableLayout_bottomShadow {
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1; }

.fixedDataTableLayout_bottomShadow {
  margin-top: -4px; }

.fixedDataTableLayout_rowsContainer {
  overflow: hidden;
  position: relative; }

.fixedDataTableLayout_horizontalScrollbar {
  bottom: 0;
  position: absolute; }

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableRowLayout
 */
.fixedDataTableRowLayout_main {
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 0; }

.fixedDataTableRowLayout_body {
  left: 0;
  position: absolute;
  top: 0; }

.fixedDataTableRowLayout_fixedColumnsDivider {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-left-style: solid;
  border-left-width: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 0; }

.fixedDataTableRowLayout_columnsShadow {
  position: absolute;
  width: 4px; }

.fixedDataTableRowLayout_columnsRightShadow {
  right: 1px; }

.fixedDataTableRowLayout_rowWrapper {
  position: absolute;
  top: 0; }

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule ScrollbarLayout
 */
.ScrollbarLayout_main {
  box-sizing: border-box;
  outline: none;
  overflow: hidden;
  position: absolute;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ScrollbarLayout_mainVertical {
  bottom: 0;
  right: 0;
  top: 0;
  -webkit-transition-property: background-color width;
  transition-property: background-color width;
  width: 15px; }

.ScrollbarLayout_mainVertical.public_Scrollbar_mainActive,
.ScrollbarLayout_mainVertical:hover {
  width: 17px; }

.ScrollbarLayout_mainHorizontal {
  bottom: 0;
  height: 15px;
  left: 0;
  -webkit-transition-property: background-color height;
  transition-property: background-color height; }

/* Touching the scroll-track directly makes the scroll-track bolder */
.ScrollbarLayout_mainHorizontal.public_Scrollbar_mainActive,
.ScrollbarLayout_mainHorizontal:hover {
  height: 17px; }

.ScrollbarLayout_face {
  left: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1; }

/**
 * This selector renders the "nub" of the scrollface. The nub must
 * be rendered as pseudo-element so that it won't receive any UI events then
 * we can get the correct `event.offsetX` and `event.offsetY` from the
 * scrollface element while dragging it.
 */
.ScrollbarLayout_face:after {
  border-radius: 6px;
  content: '';
  display: block;
  position: absolute;
  -webkit-transition: background-color 250ms ease;
  transition: background-color 250ms ease; }

.ScrollbarLayout_faceHorizontal {
  bottom: 0;
  left: 0;
  top: 0; }

.ScrollbarLayout_faceHorizontal:after {
  bottom: 4px;
  left: 0;
  top: 4px;
  width: 100%; }

.ScrollbarLayout_faceVertical {
  left: 0;
  right: 0;
  top: 0; }

.ScrollbarLayout_faceVertical:after {
  height: 100%;
  left: 4px;
  right: 4px;
  top: 0; }

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTable
 *
 */
/**
 * Table.
 */
.public_fixedDataTable_main {
  border-color: #d3d3d3; }

.public_fixedDataTable_header,
.public_fixedDataTable_hasBottomBorder {
  border-color: #d3d3d3; }

.public_fixedDataTable_header .public_fixedDataTableCell_main {
  font-weight: bold; }

.public_fixedDataTable_header,
.public_fixedDataTable_header .public_fixedDataTableCell_main {
  background-color: #f6f7f8;
  background-image: -webkit-linear-gradient(#fff, #efefef);
  background-image: linear-gradient(#fff, #efefef); }

.public_fixedDataTable_footer .public_fixedDataTableCell_main {
  background-color: #f6f7f8;
  border-color: #d3d3d3; }

.public_fixedDataTable_topShadow {
  background: 0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=) repeat-x; }

.public_fixedDataTable_bottomShadow {
  background: 0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAHElEQVQI12MwNjZmZdAT1+Nm0JDWEGZQk1GTBgAWkwIeAEp52AAAAABJRU5ErkJggg==) repeat-x; }

.public_fixedDataTable_horizontalScrollbar .public_Scrollbar_mainHorizontal {
  background-color: #fff; }

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableCell
 */
/**
 * Table cell.
 */
.public_fixedDataTableCell_main {
  background-color: #fff;
  border-color: #d3d3d3; }

.public_fixedDataTableCell_highlighted {
  background-color: #f4f4f4; }

.public_fixedDataTableCell_cellContent {
  padding: 8px; }

.public_fixedDataTableCell_columnResizerKnob {
  background-color: #0284ff; }

.public_fixedDataTableCell_hasReorderHandle .public_fixedDataTableCell_cellContent {
  margin-left: 12px; }

/**
 * Column reorder goodies.
 */
.fixedDataTableCellLayout_columnReorderContainer {
  border-color: #0284ff;
  background-color: rgba(0, 0, 0, 0.1);
  width: 12px;
  margin-right: -12px;
  float: left;
  cursor: move; }

.fixedDataTableCellLayout_columnReorderContainer:after {
  content: '::';
  position: absolute;
  top: 50%;
  left: 1px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableColumnResizerLine
 *
 */
/**
 * Column resizer line.
 */
.public_fixedDataTableColumnResizerLine_main {
  border-color: #0284ff; }

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableRow
 */
/**
 * Table row.
 */
.public_fixedDataTableRow_main {
  background-color: #fff; }

.public_fixedDataTableRow_highlighted,
.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
  background-color: #f6f7f8; }

.public_fixedDataTableRow_fixedColumnsDivider {
  border-color: #d3d3d3; }

.public_fixedDataTableRow_columnsShadow {
  background: 0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==) repeat-y; }

.public_fixedDataTableRow_columnsRightShadow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule Scrollbar
 *
 */
/**
 * Scrollbars.
 */
/* Touching the scroll-track directly makes the scroll-track bolder */
.public_Scrollbar_main.public_Scrollbar_mainActive,
.public_Scrollbar_main:hover {
  background-color: rgba(255, 255, 255, 0.8); }

.public_Scrollbar_mainOpaque,
.public_Scrollbar_mainOpaque.public_Scrollbar_mainActive,
.public_Scrollbar_mainOpaque:hover {
  background-color: #fff; }

.public_Scrollbar_face:after {
  background-color: #c2c2c2; }

.public_Scrollbar_main:hover .public_Scrollbar_face:after,
.public_Scrollbar_mainActive .public_Scrollbar_face:after,
.public_Scrollbar_faceActive:after {
  background-color: #7d7d7d; }

meta.foundation-version {
  font-family: "/5.5.3/"; }

meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0; }

meta.foundation-mq-small-only {
  font-family: "/only screen and (max-width: 40em)/";
  width: 0; }

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width:40.0625em)/";
  width: 40.0625em; }

meta.foundation-mq-medium-only {
  font-family: "/only screen and (min-width:40.0625em) and (max-width:64em)/";
  width: 40.0625em; }

meta.foundation-mq-large {
  font-family: "/only screen and (min-width:64.0625em)/";
  width: 64.0625em; }

meta.foundation-mq-large-only {
  font-family: "/only screen and (min-width:64.0625em) and (max-width:90em)/";
  width: 64.0625em; }

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:90.0625em)/";
  width: 90.0625em; }

meta.foundation-mq-xlarge-only {
  font-family: "/only screen and (min-width:90.0625em) and (max-width:120em)/";
  width: 90.0625em; }

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:120.0625em)/";
  width: 120.0625em; }

meta.foundation-data-attribute-namespace {
  font-family: false; }

.gd-color-highlight {
  background: #14b2e2; }

.gd-color-positive {
  background: #00c18d; }

.gd-color-negative {
  background: #e54d42; }

.gd-color-warning {
  background: #fada23; }

.gd-color-highlight-dimmed {
  background: #e8f7fc; }

.gd-color-positive-dimmed {
  background: #e6f9f4; }

.gd-color-negative-dimmed {
  background: #fcedec; }

.gd-color-warning-dimmed {
  background: #fef8d3; }

.gd-color-light {
  border: 1px solid #ddd;
  background: #fcfcfd; }

.gd-color-disabled {
  background: #b0beca; }

.gd-color-state-blank {
  background: #94a1ad; }

.gd-color-link {
  background: #6d7680; }

.gd-color-text {
  background: #464e56; }

.gd-color-dark {
  background: #000; }

.gd-flex-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column; }

.gd-flex-list-container {
  flex: 1 1 auto; }

.gd-flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-flow: row wrap;
  align-content: stretch; }

.gd-flex-row-container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  flex-flow: column nowrap;
  align-content: stretch; }

.gd-flex-item {
  flex: 0 0 auto; }

.gd-flex-item-stretch {
  flex: 1 1 auto;
  overflow-y: auto; }

@media only screen and (max-width: 40em) {
  .gd-flex-wrapper-mobile {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column; }
  .gd-flex-list-container-mobile {
    flex: 1 1 auto; }
  .gd-flex-container-mobile {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-flow: row wrap;
    align-content: stretch; }
  .gd-flex-row-container-mobile {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
    flex-flow: column nowrap;
    align-content: stretch; }
  .gd-flex-item-mobile {
    flex: 0 0 auto; }
  .gd-flex-item-stretch-mobile {
    flex: 1 1 auto;
    overflow-y: auto; } }

/* allow vendor prefixes and disable max length */
/* stylelint-disable max-line-length */
/* stylelint-enable max-line-length */
/* allow vendor prefixes and disable max length */
/* stylelint-disable value-no-vendor-prefix, declaration-block-no-duplicate-properties, max-line-length */
.overlay-wrapper {
  z-index: 5001; }

.overlay {
  font-family: avenir, "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  line-height: 1.4rem;
  color: #464e56;
  font-size: 14px;
  position: relative;
  padding: 0.1px;
  border-radius: 3px;
  font-family: avenir, "Helvetica Neue", arial, sans-serif;
  background-color: #fff;
  box-shadow: 0 1px 20px rgba(20,56,93,.2); }
  @media only screen and (max-width: 40em) {
    .overlay {
      border-radius: 0; } }

.gd-dialog,
.overlay.gd-dialog {
  position: relative;
  z-index: 2;
  padding: 20px;
  outline: 0;
  max-width: 600px;
  /**
     * Row inside a row has negative margin.
     * Dialog can be inserted anywhere to the DOM,
     * we have to set default value to be consistent
     * across all applications
     */ }
  .gd-dialog .row,
  .overlay.gd-dialog .row {
    width: auto;
    margin-left: -15px;
    margin-right: -15px; }
  .gd-dialog .gd-dialog-close,
  .gd-dialog .gd-dialog-close.gd-button-link,
  .gd-dialog .gd-dialog-close.gd-button-link-dimmed,
  .overlay.gd-dialog .gd-dialog-close,
  .overlay.gd-dialog .gd-dialog-close.gd-button-link,
  .overlay.gd-dialog .gd-dialog-close.gd-button-link-dimmed {
    position: absolute;
    z-index: 5;
    top: 14px;
    right: 4px;
    outline: none; }
    .gd-dialog .gd-dialog-close::before,
    .gd-dialog .gd-dialog-close.gd-button-link::before,
    .gd-dialog .gd-dialog-close.gd-button-link-dimmed::before,
    .overlay.gd-dialog .gd-dialog-close::before,
    .overlay.gd-dialog .gd-dialog-close.gd-button-link::before,
    .overlay.gd-dialog .gd-dialog-close.gd-button-link-dimmed::before {
      color: #6d7680; }
    .gd-dialog .gd-dialog-close:hover, .gd-dialog .gd-dialog-close:focus, .gd-dialog .gd-dialog-close:active,
    .gd-dialog .gd-dialog-close.gd-button-link:hover,
    .gd-dialog .gd-dialog-close.gd-button-link-dimmed:hover,
    .gd-dialog .gd-dialog-close.gd-button-link:focus,
    .gd-dialog .gd-dialog-close.gd-button-link-dimmed:focus,
    .gd-dialog .gd-dialog-close.gd-button-link:active,
    .gd-dialog .gd-dialog-close.gd-button-link-dimmed:active,
    .overlay.gd-dialog .gd-dialog-close:hover,
    .overlay.gd-dialog .gd-dialog-close:focus,
    .overlay.gd-dialog .gd-dialog-close:active,
    .overlay.gd-dialog .gd-dialog-close.gd-button-link:hover,
    .overlay.gd-dialog .gd-dialog-close.gd-button-link-dimmed:hover,
    .overlay.gd-dialog .gd-dialog-close.gd-button-link:focus,
    .overlay.gd-dialog .gd-dialog-close.gd-button-link-dimmed:focus,
    .overlay.gd-dialog .gd-dialog-close.gd-button-link:active,
    .overlay.gd-dialog .gd-dialog-close.gd-button-link-dimmed:active {
      box-shadow: none;
      border-color: transparent; }
      .gd-dialog .gd-dialog-close:hover::before, .gd-dialog .gd-dialog-close:focus::before, .gd-dialog .gd-dialog-close:active::before,
      .gd-dialog .gd-dialog-close.gd-button-link:hover::before,
      .gd-dialog .gd-dialog-close.gd-button-link-dimmed:hover::before,
      .gd-dialog .gd-dialog-close.gd-button-link:focus::before,
      .gd-dialog .gd-dialog-close.gd-button-link-dimmed:focus::before,
      .gd-dialog .gd-dialog-close.gd-button-link:active::before,
      .gd-dialog .gd-dialog-close.gd-button-link-dimmed:active::before,
      .overlay.gd-dialog .gd-dialog-close:hover::before,
      .overlay.gd-dialog .gd-dialog-close:focus::before,
      .overlay.gd-dialog .gd-dialog-close:active::before,
      .overlay.gd-dialog .gd-dialog-close.gd-button-link:hover::before,
      .overlay.gd-dialog .gd-dialog-close.gd-button-link-dimmed:hover::before,
      .overlay.gd-dialog .gd-dialog-close.gd-button-link:focus::before,
      .overlay.gd-dialog .gd-dialog-close.gd-button-link-dimmed:focus::before,
      .overlay.gd-dialog .gd-dialog-close.gd-button-link:active::before,
      .overlay.gd-dialog .gd-dialog-close.gd-button-link-dimmed:active::before {
        color: #14b2e2; }

.gd-confirm {
  max-width: 350px; }
  .gd-confirm .gd-dialog-close,
  .gd-confirm .gd-dialog-close.gd-button-link,
  .gd-confirm .gd-dialog-close.gd-button-link-dimmed {
    top: 10px; }

.gd-dialog-header {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 30px 20px 0; }
  .gd-dialog-header h2,
  .gd-dialog-header h3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.2; }
  .gd-dialog-header h2 {
    color: #000;
    font-family: avenir, "Helvetica Neue", arial, sans-serif;
    font-weight: 400;
    font-weight: 700;
    line-height: 26px;
    font-size: 20px; }
  .gd-dialog-header h3 {
    color: #000;
    font-family: avenir, "Helvetica Neue", arial, sans-serif;
    font-weight: 400;
    font-weight: 400;
    line-height: 23px;
    font-size: 17px; }

.gd-dialog-icon {
  color: #000;
  font-family: avenir, "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  font-weight: 700;
  line-height: 30px;
  font-size: 24px;
  display: inline-block;
  width: 35px;
  vertical-align: middle; }
  .gd-dialog-icon::before {
    color: #14b2e2; }

.gd-dialog-section, .gd-dialog-section-separated {
  margin-bottom: 20px; }
  .gd-dialog-section + .gd-dialog-footer, .gd-dialog-section-separated + .gd-dialog-footer {
    margin-top: 30px; }

.gd-dialog-section-separated {
  padding-bottom: 20px;
  border-bottom: 1px solid #dde4eb; }
  .gd-dialog-section-separated + .gd-dialog-section-separated {
    margin-top: 20px; }

.gd-dialog-warning {
  width: 100%;
  margin-bottom: 12px;
  padding: 8px;
  border-radius: 4px;
  background-color: #fff3f2;
  color: #e54d42; }

.gd-dialog-content p:first-of-type,
.gd-dialog-section p:first-of-type,
.gd-dialog-section-separated p:first-of-type,
.gd-dialog-section-separated p:first-of-type {
  margin-top: 0; }

.gd-dialog-content p:last-of-type,
.gd-dialog-section p:last-of-type,
.gd-dialog-section-separated p:last-of-type,
.gd-dialog-section-separated p:last-of-type {
  margin-bottom: 0; }

.gd-dialog-footer {
  margin-top: 20px;
  text-align: right; }

/* Export Dialog
   ========================================================================== */
.gd-export-dialog {
  min-width: 350px; }
  .gd-export-dialog .gd-dialog-header h3 {
    margin: 0;
    padding: 0; }
  .gd-export-dialog h6 {
    margin: 0;
    padding: 12px 0 0; }
  .gd-export-dialog h6:first-child {
    padding-top: 0; }
  .gd-export-dialog .input-label-text {
    margin-left: 3px; }

/* ==========================================================================
   Variables
   ========================================================================== */
/* ==========================================================================
   Silent classes - more clever mixins
   ========================================================================== */
.gd-button-primary, .gd-button-link.gd-button-icon-only[class*="icon-"], .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"],
.gd-button-secondary, .gd-button-positive, .gd-button-negative, .gd-button-action {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 14px;
  border: 1px solid transparent;
  font: 400 14px/20px avenir, "Helvetica Neue", arial, sans-serif;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  text-align: left;
  -moz-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  border-radius: 3px; }
  .gd-button-primary::before, .gd-button-link.gd-button-icon-only[class*="icon-"]::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]::before,
  .gd-button-secondary::before, .gd-button-positive::before, .gd-button-negative::before, .gd-button-action::before, .gd-button-primary::after, .gd-button-link.gd-button-icon-only[class*="icon-"]::after, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]::after,
  .gd-button-secondary::after, .gd-button-positive::after, .gd-button-negative::after, .gd-button-action::after {
    position: absolute;
    top: 50%;
    height: 20px;
    line-height: 20px;
    color: #b0beca;
    transform: translateY(-50%);
    font-size: 18px;
    text-align: center;
    -moz-transition-property: color;
    -webkit-transition-property: color;
    transition-property: color;
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out; }
  .gd-button-primary::before, .gd-button-link.gd-button-icon-only[class*="icon-"]::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]::before,
  .gd-button-secondary::before, .gd-button-positive::before, .gd-button-negative::before, .gd-button-action::before {
    left: 10px;
    margin-right: 0.5em; }
  .gd-button-primary::after, .gd-button-link.gd-button-icon-only[class*="icon-"]::after, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]::after,
  .gd-button-secondary::after, .gd-button-positive::after, .gd-button-negative::after, .gd-button-action::after {
    right: 7px;
    margin-left: 0.5em; }
  .gd-button-primary .gd-button-text, .gd-button-link.gd-button-icon-only[class*="icon-"] .gd-button-text, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"] .gd-button-text, .gd-button-secondary .gd-button-text, .gd-button-positive .gd-button-text, .gd-button-negative .gd-button-text, .gd-button-action .gd-button-text,
  .gd-button-primary .gd-button-icon,
  .gd-button-link.gd-button-icon-only[class*="icon-"] .gd-button-icon,
  .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"] .gd-button-icon,
  .gd-button-secondary .gd-button-icon,
  .gd-button-positive .gd-button-icon,
  .gd-button-negative .gd-button-icon,
  .gd-button-action .gd-button-icon {
    position: relative;
    display: block;
    justify-content: space-between;
    align-items: center; }
  .gd-button-primary .gd-button-text, .gd-button-link.gd-button-icon-only[class*="icon-"] .gd-button-text, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"] .gd-button-text, .gd-button-secondary .gd-button-text, .gd-button-positive .gd-button-text, .gd-button-negative .gd-button-text, .gd-button-action .gd-button-text {
    top: 1px;
    flex: 1 1 auto;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis; }
  
  .gd-button-primary .gd-button-icon,
  .gd-button-link.gd-button-icon-only[class*="icon-"] .gd-button-icon,
  .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"] .gd-button-icon,
  .gd-button-secondary .gd-button-icon,
  .gd-button-positive .gd-button-icon,
  .gd-button-negative .gd-button-icon,
  .gd-button-action .gd-button-icon {
    flex: 0 0 auto;
    width: 18px;
    height: 18px;
    margin: 0 0.5em;
    line-height: 18px;
    color: #b0beca;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
    .gd-button-primary .gd-button-icon::before, .gd-button-link.gd-button-icon-only[class*="icon-"] .gd-button-icon::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"] .gd-button-icon::before, .gd-button-secondary .gd-button-icon::before, .gd-button-positive .gd-button-icon::before, .gd-button-negative .gd-button-icon::before, .gd-button-action .gd-button-icon::before, .gd-button-primary .gd-button-icon::after, .gd-button-link.gd-button-icon-only[class*="icon-"] .gd-button-icon::after, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"] .gd-button-icon::after, .gd-button-secondary .gd-button-icon::after, .gd-button-positive .gd-button-icon::after, .gd-button-negative .gd-button-icon::after, .gd-button-action .gd-button-icon::after {
      color: inherit;
      -moz-transition-property: color;
      -webkit-transition-property: color;
      transition-property: color;
      -moz-transition-duration: 0.25s;
      -webkit-transition-duration: 0.25s;
      transition-duration: 0.25s;
      -moz-transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out; }
  .gd-button-primary:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:first-child, .gd-button-link.gd-button-icon-only:not(.gd-button-link):not(.gd-button-link-dimmed)[class*="icon-"] .gd-button-icon:first-child, .gd-button-icon-only.gd-button-link-dimmed:not(.gd-button-link):not(.gd-button-link-dimmed)[class*="icon-"] .gd-button-icon:first-child, .gd-button-secondary:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:first-child, .gd-button-positive:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:first-child, .gd-button-negative:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:first-child, .gd-button-action:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:first-child {
    margin-left: -4px; }
  .gd-button-primary:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:last-child, .gd-button-link.gd-button-icon-only:not(.gd-button-link):not(.gd-button-link-dimmed)[class*="icon-"] .gd-button-icon:last-child, .gd-button-icon-only.gd-button-link-dimmed:not(.gd-button-link):not(.gd-button-link-dimmed)[class*="icon-"] .gd-button-icon:last-child, .gd-button-secondary:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:last-child, .gd-button-positive:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:last-child, .gd-button-negative:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:last-child, .gd-button-action:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:last-child {
    margin-right: -4px; }
  .gd-button-primary:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:only-child, .gd-button-link.gd-button-icon-only:not(.gd-button-link):not(.gd-button-link-dimmed)[class*="icon-"] .gd-button-icon:only-child, .gd-button-icon-only.gd-button-link-dimmed:not(.gd-button-link):not(.gd-button-link-dimmed)[class*="icon-"] .gd-button-icon:only-child, .gd-button-secondary:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:only-child, .gd-button-positive:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:only-child, .gd-button-negative:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:only-child, .gd-button-action:not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:only-child {
    width: 19px;
    height: 17px;
    line-height: 17px;
    margin-right: -9px;
    margin-left: -9px;
    font-size: 16px; }
  .gd-button-primary:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::before, .gd-button-link.gd-button-icon-only[class*="icon-"]:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::before,
  .gd-button-secondary:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::before, .gd-button-positive:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::before, .gd-button-negative:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::before, .gd-button-action:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::before, .gd-button-primary:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::after, .gd-button-link.gd-button-icon-only[class*="icon-"]:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::after, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::after,
  .gd-button-secondary:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::after, .gd-button-positive:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::after, .gd-button-negative:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::after, .gd-button-action:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active::after {
    top: calc(50% + 1px); }
  .gd-button-primary:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active .gd-button-text, .gd-button-link.gd-button-icon-only:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active[class*="icon-"] .gd-button-text, .gd-button-icon-only.gd-button-link-dimmed:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active[class*="icon-"] .gd-button-text, .gd-button-secondary:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active .gd-button-text, .gd-button-positive:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active .gd-button-text, .gd-button-negative:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active .gd-button-text, .gd-button-action:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active .gd-button-text {
    top: 2px; }
  .gd-button-primary:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active .gd-button-icon, .gd-button-link.gd-button-icon-only:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active[class*="icon-"] .gd-button-icon, .gd-button-icon-only.gd-button-link-dimmed:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active[class*="icon-"] .gd-button-icon, .gd-button-secondary:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active .gd-button-icon, .gd-button-positive:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active .gd-button-icon, .gd-button-negative:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active .gd-button-icon, .gd-button-action:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed):active .gd-button-icon {
    margin-top: 2px; }
  .gd-button-primary:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:only-child, .gd-button-link.gd-button-icon-only:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed)[class*="icon-"] .gd-button-icon:only-child, .gd-button-icon-only.gd-button-link-dimmed:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed)[class*="icon-"] .gd-button-icon:only-child, .gd-button-secondary:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:only-child, .gd-button-positive:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:only-child, .gd-button-negative:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:only-child, .gd-button-action:not(.disabled):not(.gd-button-link):not(.gd-button-link-dimmed) .gd-button-icon:only-child {
    color: #778491; }
  .gd-button-primary:hover::before, .gd-button-link.gd-button-icon-only[class*="icon-"]:hover::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:hover::before,
  .gd-button-secondary:hover::before, .gd-button-positive:hover::before, .gd-button-negative:hover::before, .gd-button-action:hover::before, .gd-button-primary:hover::after, .gd-button-link.gd-button-icon-only[class*="icon-"]:hover::after, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:hover::after,
  .gd-button-secondary:hover::after, .gd-button-positive:hover::after, .gd-button-negative:hover::after, .gd-button-action:hover::after,
  .gd-button-primary:hover .gd-button-icon,
  .gd-button-link.gd-button-icon-only:hover[class*="icon-"] .gd-button-icon,
  .gd-button-icon-only.gd-button-link-dimmed:hover[class*="icon-"] .gd-button-icon,
  .gd-button-secondary:hover .gd-button-icon,
  .gd-button-positive:hover .gd-button-icon,
  .gd-button-negative:hover .gd-button-icon,
  .gd-button-action:hover .gd-button-icon {
    color: #14b2e2; }
  .gd-button-primary[class*="icon-"], .gd-button-link.gd-button-icon-only[class*="icon-"], .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"],
  .gd-button-secondary[class*="icon-"], .gd-button-positive[class*="icon-"], .gd-button-negative[class*="icon-"], .gd-button-action[class*="icon-"] {
    padding-left: 37px; }
    .gd-button-primary[class*="icon-"].icon-right, .gd-button-link.gd-button-icon-only[class*="icon-"].icon-right, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"].icon-right,
    .gd-button-secondary[class*="icon-"].icon-right, .gd-button-positive[class*="icon-"].icon-right, .gd-button-negative[class*="icon-"].icon-right, .gd-button-action[class*="icon-"].icon-right {
      padding-left: 11px;
      padding-right: 32px; }

.gd-button-primary .ss-icon, .gd-button-link.gd-button-icon-only[class*="icon-"] .ss-icon, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"] .ss-icon, .gd-button-secondary .ss-icon, .gd-button-positive .ss-icon, .gd-button-negative .ss-icon, .gd-button-action .ss-icon {
  vertical-align: middle; }

/* Basic button variants
   ========================================================================== */
/* disable max length */
/* stylelint-disable max-line-length */
.gd-button + .gd-button {
  margin-left: 10px; }

.gd-button-primary, .gd-button-link.gd-button-icon-only[class*="icon-"], .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"],
.gd-button-secondary {
  box-shadow: 0 1px 1px 0 rgba(20, 56, 93, 0.15);
  color: #778491;
  background: #fcfcfd;
  border-color: #ccd8e2; }
  .gd-button-primary:hover, .gd-button-link.gd-button-icon-only:hover[class*="icon-"], .gd-button-icon-only.gd-button-link-dimmed:hover[class*="icon-"],
  .gd-button-secondary:hover {
    box-shadow: 0 1px 1px 0 rgba(20, 56, 93, 0.15), inset 0 -1px 0 0 rgba(177, 193, 209, 0.6);
    color: #464e56;
    background: #f5f8fa;
    border-color: rgba(31, 52, 73, 0.2); }
  .gd-button-primary:focus, .gd-button-link.gd-button-icon-only:focus[class*="icon-"], .gd-button-icon-only.gd-button-link-dimmed:focus[class*="icon-"], .gd-button-primary.is-focus, .is-focus.gd-button-link.gd-button-icon-only[class*="icon-"], .is-focus.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"],
  .gd-button-secondary:focus,
  .gd-button-secondary.is-focus {
    box-shadow: 0 0 3px 1px rgba(69, 199, 239, 0), 0 1px 2px 0 rgba(20, 56, 93, 0.15), inset 0 -1px 0 0 rgba(177, 193, 209, 0.6);
    border-color: rgba(20, 178, 226, 0.75); }
  .gd-button-primary:active, .gd-button-link.gd-button-icon-only:active[class*="icon-"], .gd-button-icon-only.gd-button-link-dimmed:active[class*="icon-"], .gd-button-primary.is-active, .is-active.gd-button-link.gd-button-icon-only[class*="icon-"], .is-active.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"],
  .gd-button-secondary:active,
  .gd-button-secondary.is-active {
    box-shadow: inset 0 1px 0 0 rgba(177, 193, 209, 0.65);
    color: #464e56;
    border-color: #b1c1d1;
    background: #ecf0f5;
    /* Old browsers */
    background-image: -webkit-linear-gradient(top, #dee6ef, #ecf0f5);
    /* Chrome 10+, Saf5.1+ */
    background-image: -moz-linear-gradient(top, #dee6ef, #ecf0f5);
    /* FF3.6 */
    background-image: -ms-linear-gradient(top, #dee6ef, #ecf0f5);
    /* IE10 */
    background-image: linear-gradient(to top, #dee6ef, #ecf0f5);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#FFDEE6EF', EndColorStr='#FFECF0F5');
    /* IE6-IE9 */ }

.gd-button-positive,
.gd-button-negative,
.gd-button-action {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #fff; }
  .gd-button-positive[class*="icon-"],
  .gd-button-positive .gd-button-icon,
  .gd-button-negative[class*="icon-"],
  .gd-button-negative .gd-button-icon,
  .gd-button-action[class*="icon-"],
  .gd-button-action .gd-button-icon {
    color: #fff; }
    .gd-button-positive[class*="icon-"]::before, .gd-button-positive[class*="icon-"]::after,
    .gd-button-positive[class*="icon-"] .gd-button-icon, .gd-button-positive[class*="icon-"]:hover::before, .gd-button-positive[class*="icon-"]:hover::after,
    .gd-button-positive[class*="icon-"]:hover .gd-button-icon,
    .gd-button-positive .gd-button-icon::before,
    .gd-button-positive .gd-button-icon::after,
    .gd-button-positive .gd-button-icon .gd-button-icon,
    .gd-button-positive .gd-button-icon:hover::before,
    .gd-button-positive .gd-button-icon:hover::after,
    .gd-button-positive .gd-button-icon:hover .gd-button-icon,
    .gd-button-negative[class*="icon-"]::before,
    .gd-button-negative[class*="icon-"]::after,
    .gd-button-negative[class*="icon-"] .gd-button-icon,
    .gd-button-negative[class*="icon-"]:hover::before,
    .gd-button-negative[class*="icon-"]:hover::after,
    .gd-button-negative[class*="icon-"]:hover .gd-button-icon,
    .gd-button-negative .gd-button-icon::before,
    .gd-button-negative .gd-button-icon::after,
    .gd-button-negative .gd-button-icon .gd-button-icon,
    .gd-button-negative .gd-button-icon:hover::before,
    .gd-button-negative .gd-button-icon:hover::after,
    .gd-button-negative .gd-button-icon:hover .gd-button-icon,
    .gd-button-action[class*="icon-"]::before,
    .gd-button-action[class*="icon-"]::after,
    .gd-button-action[class*="icon-"] .gd-button-icon,
    .gd-button-action[class*="icon-"]:hover::before,
    .gd-button-action[class*="icon-"]:hover::after,
    .gd-button-action[class*="icon-"]:hover .gd-button-icon,
    .gd-button-action .gd-button-icon::before,
    .gd-button-action .gd-button-icon::after,
    .gd-button-action .gd-button-icon .gd-button-icon,
    .gd-button-action .gd-button-icon:hover::before,
    .gd-button-action .gd-button-icon:hover::after,
    .gd-button-action .gd-button-icon:hover .gd-button-icon {
      color: #fff; }

.gd-button-positive,
.gd-button-negative,
.gd-button-action, .gd-button-primary, .gd-button-link.gd-button-icon-only[class*="icon-"], .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"] {
  font-weight: 700; }

.gd-button-positive {
  box-shadow: 0 1px 1px 0 rgba(20, 56, 93, 0.09);
  background: #00c18d; }
  .gd-button-positive:hover {
    box-shadow: 0 1px 1px 0 rgba(20, 56, 93, 0.15), inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
    background: #00a277; }
  .gd-button-positive:focus {
    box-shadow: 0 0 3px 1px rgba(0, 224, 163, 0.5), 0 1px 1px 0 rgba(20, 56, 93, 0.15), inset 0 -2px 0 0 rgba(0, 0, 0, 0.15); }
  .gd-button-positive:active, .gd-button-positive.is-active {
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.15);
    background: #00a277;
    /* Old browsers */
    background-image: -webkit-linear-gradient(top, #008460, #00a277);
    /* Chrome 10+, Saf5.1+ */
    background-image: -moz-linear-gradient(top, #008460, #00a277);
    /* FF3.6 */
    background-image: -ms-linear-gradient(top, #008460, #00a277);
    /* IE10 */
    background-image: linear-gradient(to top, #008460, #00a277);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#FF008460', EndColorStr='#FF00A277');
    /* IE6-IE9 */ }

.gd-button-negative {
  box-shadow: 0 1px 0 0 rgba(20, 56, 93, 0.09);
  background: #e54d42; }
  .gd-button-negative:hover {
    box-shadow: 0 1px 1px 0 rgba(20, 56, 93, 0.15), inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
    background: #d62a1e; }
  .gd-button-negative:focus {
    box-shadow: 0 0 3px 1px rgba(235, 119, 111, 0.6), 0 1px 1px 0 rgba(20, 56, 93, 0.15), inset 0 -1px 0 0 rgba(0, 0, 0, 0.15); }
  .gd-button-negative:active, .gd-button-negative.is-active {
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.15);
    background: #d62a1e;
    /* Old browsers */
    background-image: -webkit-linear-gradient(top, #aa2117, #d62a1e);
    /* Chrome 10+, Saf5.1+ */
    background-image: -moz-linear-gradient(top, #aa2117, #d62a1e);
    /* FF3.6 */
    background-image: -ms-linear-gradient(top, #aa2117, #d62a1e);
    /* IE10 */
    background-image: linear-gradient(to top, #aa2117, #d62a1e);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#FFAA2117', EndColorStr='#FFD62A1E');
    /* IE6-IE9 */ }

.gd-button-action {
  box-shadow: 1px 1px 0 rgba(20, 56, 93, 0.09);
  background: #14b2e2; }
  .gd-button-action:hover {
    box-shadow: 0 1px 1px 0 rgba(20, 56, 93, 0.15), inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
    background: #129cc6; }
  .gd-button-action:focus {
    box-shadow: 0 0 3px 1px rgba(41, 190, 236, 0.6), 0 1px 1px 0 rgba(20, 56, 93, 0.15), inset 0 -1px 0 0 rgba(0, 0, 0, 0.15); }
  .gd-button-action:active, .gd-button-action.is-active {
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.15);
    background: #129cc6;
    /* Old browsers */
    background-image: -webkit-linear-gradient(top, #0f86aa, #129cc6);
    /* Chrome 10+, Saf5.1+ */
    background-image: -moz-linear-gradient(top, #0f86aa, #129cc6);
    /* FF3.6 */
    background-image: -ms-linear-gradient(top, #0f86aa, #129cc6);
    /* IE10 */
    background-image: linear-gradient(to top, #0f86aa, #129cc6);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#FF0F86AA', EndColorStr='#FF129CC6');
    /* IE6-IE9 */ }

/* Button links
   ========================================================================== */
.gd-button-link[class*="icon-"], .gd-button-link-dimmed[class*="icon-"] {
  position: relative;
  padding-left: 32px; }

.gd-button-link[class*="icon-"].icon-right, .gd-button-link-dimmed[class*="icon-"].icon-right {
  padding-left: 11px;
  padding-right: 30px; }
  .gd-button-link[class*="icon-"].icon-right::after, .gd-button-link-dimmed[class*="icon-"].icon-right::after {
    position: absolute;
    right: 5px;
    font-size: 18px; }

.gd-button-link, .gd-button-link-dimmed {
  box-sizing: border-box;
  display: inline-flex;
  padding: 0 6px;
  border: 1px solid transparent;
  font: 400 14px/30px avenir, "Helvetica Neue", arial, sans-serif;
  vertical-align: middle;
  color: #14b2e2;
  background: none;
  cursor: pointer;
  -moz-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out; }
  .gd-button-link > span, .gd-button-link-dimmed > span {
    text-decoration: none; }
  .gd-button-link::before, .gd-button-link-dimmed::before, .gd-button-link::after, .gd-button-link-dimmed::after {
    position: absolute;
    top: 50%;
    width: 20px;
    transform: translateY(-50%);
    font-size: 18px;
    text-align: center;
    -moz-transition-property: color;
    -webkit-transition-property: color;
    transition-property: color;
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out; }
  .gd-button-link::before, .gd-button-link-dimmed::before, .gd-button-link::after, .gd-button-link-dimmed::after,
  .gd-button-link .gd-button-icon,
  .gd-button-link-dimmed .gd-button-icon {
    color: #14b2e2; }
  .gd-button-link::before, .gd-button-link-dimmed::before {
    left: 6px; }
  .gd-button-link::after, .gd-button-link-dimmed::after {
    right: 6px; }
  
  .gd-button-link .gd-button-icon,
  .gd-button-link-dimmed .gd-button-icon {
    width: 20px;
    margin: 0 6px;
    font-size: 18px; }
    .gd-button-link .gd-button-icon:first-child, .gd-button-link-dimmed .gd-button-icon:first-child {
      margin-left: 0; }
    .gd-button-link .gd-button-icon:last-child, .gd-button-link-dimmed .gd-button-icon:last-child {
      margin-right: 0; }
    .gd-button-link .gd-button-icon:only-child, .gd-button-link-dimmed .gd-button-icon:only-child {
      position: relative;
      left: -3px; }
  .gd-button-link:hover, .gd-button-link-dimmed:hover, .gd-button-link:active, .gd-button-link-dimmed:active, .gd-button-link.is-active, .is-active.gd-button-link-dimmed {
    color: #464e56; }
    .gd-button-link:hover::after, .gd-button-link-dimmed:hover::after, .gd-button-link:hover::before, .gd-button-link-dimmed:hover::before,
    .gd-button-link:hover .gd-button-icon,
    .gd-button-link-dimmed:hover .gd-button-icon, .gd-button-link:active::after, .gd-button-link-dimmed:active::after, .gd-button-link:active::before, .gd-button-link-dimmed:active::before,
    .gd-button-link:active .gd-button-icon,
    .gd-button-link-dimmed:active .gd-button-icon, .gd-button-link.is-active::after, .is-active.gd-button-link-dimmed::after, .gd-button-link.is-active::before, .is-active.gd-button-link-dimmed::before,
    .gd-button-link.is-active .gd-button-icon,
    .is-active.gd-button-link-dimmed .gd-button-icon {
      color: #14b2e2; }
  .gd-button-link:focus, .gd-button-link-dimmed:focus {
    box-shadow: 0 0 3px 0 rgba(20, 178, 226, 0.2);
    color: #14b2e2;
    border-color: rgba(20, 178, 226, 0.3);
    border-radius: 2px; }
  .gd-button-link:hover .gd-button-text, .gd-button-link-dimmed:hover .gd-button-text, .gd-button-link:focus .gd-button-text, .gd-button-link-dimmed:focus .gd-button-text, .gd-button-link:active .gd-button-text, .gd-button-link-dimmed:active .gd-button-text, .gd-button-link.is-active .gd-button-text, .is-active.gd-button-link-dimmed .gd-button-text {
    text-decoration: underline; }

.gd-button-link-dimmed {
  color: #778491; }
  .gd-button-link-dimmed::before, .gd-button-link-dimmed::after,
  .gd-button-link-dimmed .gd-button-icon {
    color: #b0beca; }
  .gd-button-link-dimmed.is-active, .gd-button-link-dimmed:hover, .gd-button-link-dimmed:focus, .gd-button-link-dimmed:active {
    color: #000; }
  .gd-button-link-dimmed .gd-button-text {
    text-decoration: underline; }

.gd-button-link.gd-button-icon-only[class*="icon-"], .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"] {
  width: 31px;
  height: 29px;
  line-height: 27px; }
  .gd-button-link.gd-button-icon-only[class*="icon-"], .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"], .gd-button-link.gd-button-icon-only[class*="icon-"]:hover, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:hover, .gd-button-link.gd-button-icon-only[class*="icon-"]:focus, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:focus, .gd-button-link.gd-button-icon-only[class*="icon-"]:active, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:active {
    border: 0;
    box-shadow: none;
    background: none;
    padding-left: 0; }
  .gd-button-link.gd-button-icon-only[class*="icon-"].gd-button-small::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"].gd-button-small::before {
    font-size: 16px; }
  .gd-button-link.gd-button-icon-only[class*="icon-"]::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]::before,
  .gd-button-link.gd-button-icon-only[class*="icon-"] .gd-button-icon,
  .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"] .gd-button-icon {
    color: #6d7680; }
  .gd-button-link.gd-button-icon-only[class*="icon-"]:active::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:active::before,
  .gd-button-link.gd-button-icon-only[class*="icon-"]:active .gd-button-icon,
  .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:active .gd-button-icon {
    color: #129cc6; }
  .gd-button-link.gd-button-icon-only[class*="icon-"]:focus::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:focus::before,
  .gd-button-link.gd-button-icon-only[class*="icon-"]:focus .gd-button-icon,
  .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:focus .gd-button-icon, .gd-button-link.gd-button-icon-only[class*="icon-"]:hover::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:hover::before,
  .gd-button-link.gd-button-icon-only[class*="icon-"]:hover .gd-button-icon,
  .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:hover .gd-button-icon {
    color: #14b2e2; }
  .gd-button-link.gd-button-icon-only[class*="icon-"].disabled::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"].disabled::before,
  .gd-button-link.gd-button-icon-only[class*="icon-"].disabled .gd-button-icon,
  .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"].disabled .gd-button-icon, .gd-button-link.gd-button-icon-only[class*="icon-"].disabled:hover::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"].disabled:hover::before,
  .gd-button-link.gd-button-icon-only[class*="icon-"].disabled:hover .gd-button-icon,
  .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"].disabled:hover .gd-button-icon, .gd-button-link.gd-button-icon-only[class*="icon-"].disabled:focus::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"].disabled:focus::before,
  .gd-button-link.gd-button-icon-only[class*="icon-"].disabled:focus .gd-button-icon,
  .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"].disabled:focus .gd-button-icon, .gd-button-link.gd-button-icon-only[class*="icon-"].disabled:active::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"].disabled:active::before,
  .gd-button-link.gd-button-icon-only[class*="icon-"].disabled:active .gd-button-icon,
  .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"].disabled:active .gd-button-icon {
    color: #b0beca; }

:not(button).gd-button-link, .gd-button-link-dimmed:not(button), :not(button).gd-button-link-dimmed {
  line-height: 0.8; }
  :not(button).gd-button-link:focus, .gd-button-link-dimmed:not(button):focus, :not(button).gd-button-link-dimmed:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent; }

.button-dropdown {
  font-weight: 400; }
  .button-dropdown::after, .button-dropdown::before,
  .button-dropdown .gd-button-icon {
    color: #94a1ad; }
  .button-dropdown:focus::after, .button-dropdown:focus::before,
  .button-dropdown:focus .gd-button-icon, .button-dropdown:hover::after, .button-dropdown:hover::before,
  .button-dropdown:hover .gd-button-icon, .button-dropdown:active::after, .button-dropdown:active::before,
  .button-dropdown:active .gd-button-icon, .button-dropdown.is-active::after, .button-dropdown.is-active::before,
  .button-dropdown.is-active .gd-button-icon {
    color: #6d7680; }

/* Button sizes
   ========================================================================== */
.gd-button-small {
  height: 27px;
  padding: 0 11px;
  line-height: 25px;
  font-size: 12px; }
  .gd-button-small[class*="icon-"] {
    padding-left: 32px; }
    .gd-button-small[class*="icon-"]::before, .gd-button-small[class*="icon-"]::after {
      width: 18px;
      font-size: 15px;
      -moz-transition-property: color;
      -webkit-transition-property: color;
      transition-property: color;
      -moz-transition-duration: 0.25s;
      -webkit-transition-duration: 0.25s;
      transition-duration: 0.25s;
      -moz-transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out; }
    .gd-button-small[class*="icon-"]::before {
      left: 9px; }
    .gd-button-small[class*="icon-"]::after {
      right: 7px; }
    .gd-button-small[class*="icon-"].gd-button-icon-only {
      height: 27px; }
      .gd-button-small[class*="icon-"].gd-button-icon-only::before {
        width: auto; }
  .gd-button-small .gd-button-icon {
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 16px; }

.gd-button-important {
  height: 38px;
  padding: 7px 19px;
  line-height: 22px;
  font-size: 16px; }

.gd-button.gd-button-icon-only {
  width: 31px;
  height: 29px;
  line-height: 27px;
  color: #778491;
  padding-left: 0; }
  .gd-button.gd-button-icon-only::before {
    right: 0;
    left: 0;
    margin: 0;
    padding: 0;
    color: #778491;
    font-size: 16px; }
  .gd-button.gd-button-icon-only:hover::before,
  .gd-button.gd-button-icon-only:hover .gd-button-icon {
    color: #14b2e2; }

/* Button states
   ========================================================================== */
.gd-button.disabled, .gd-button.disabled:hover, .gd-button.disabled:focus, .gd-button.disabled:active {
  box-shadow: none;
  background: transparent;
  cursor: default;
  border-color: transparent;
  text-decoration: none; }

.gd-button-primary.disabled, .disabled.gd-button-link.gd-button-icon-only[class*="icon-"], .disabled.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"],
.gd-button-secondary.disabled {
  cursor: default; }
  .gd-button-primary.disabled, .disabled.gd-button-link.gd-button-icon-only[class*="icon-"], .disabled.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"], .gd-button-primary.disabled:hover, .disabled.gd-button-link.gd-button-icon-only:hover[class*="icon-"], .disabled.gd-button-icon-only.gd-button-link-dimmed:hover[class*="icon-"], .gd-button-primary.disabled:focus, .disabled.gd-button-link.gd-button-icon-only:focus[class*="icon-"], .disabled.gd-button-icon-only.gd-button-link-dimmed:focus[class*="icon-"], .gd-button-primary.disabled:active, .disabled.gd-button-link.gd-button-icon-only:active[class*="icon-"], .disabled.gd-button-icon-only.gd-button-link-dimmed:active[class*="icon-"],
  .gd-button-secondary.disabled,
  .gd-button-secondary.disabled:hover,
  .gd-button-secondary.disabled:focus,
  .gd-button-secondary.disabled:active {
    color: #b0beca;
    background: rgba(252, 252, 253, 0.3);
    border-color: rgba(210, 219, 227, 0.75); }
    .gd-button-primary.disabled::before, .disabled.gd-button-link.gd-button-icon-only[class*="icon-"]::before, .disabled.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]::before, .gd-button-primary.disabled::after, .disabled.gd-button-link.gd-button-icon-only[class*="icon-"]::after, .disabled.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]::after,
    .gd-button-primary.disabled .gd-button-icon,
    .disabled.gd-button-link.gd-button-icon-only[class*="icon-"] .gd-button-icon,
    .disabled.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"] .gd-button-icon, .gd-button-primary.disabled:hover::before, .disabled.gd-button-link.gd-button-icon-only[class*="icon-"]:hover::before, .disabled.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:hover::before, .gd-button-primary.disabled:hover::after, .disabled.gd-button-link.gd-button-icon-only[class*="icon-"]:hover::after, .disabled.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:hover::after,
    .gd-button-primary.disabled:hover .gd-button-icon,
    .disabled.gd-button-link.gd-button-icon-only:hover[class*="icon-"] .gd-button-icon,
    .disabled.gd-button-icon-only.gd-button-link-dimmed:hover[class*="icon-"] .gd-button-icon, .gd-button-primary.disabled:focus::before, .disabled.gd-button-link.gd-button-icon-only[class*="icon-"]:focus::before, .disabled.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:focus::before, .gd-button-primary.disabled:focus::after, .disabled.gd-button-link.gd-button-icon-only[class*="icon-"]:focus::after, .disabled.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:focus::after,
    .gd-button-primary.disabled:focus .gd-button-icon,
    .disabled.gd-button-link.gd-button-icon-only:focus[class*="icon-"] .gd-button-icon,
    .disabled.gd-button-icon-only.gd-button-link-dimmed:focus[class*="icon-"] .gd-button-icon, .gd-button-primary.disabled:active::before, .disabled.gd-button-link.gd-button-icon-only[class*="icon-"]:active::before, .disabled.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:active::before, .gd-button-primary.disabled:active::after, .disabled.gd-button-link.gd-button-icon-only[class*="icon-"]:active::after, .disabled.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]:active::after,
    .gd-button-primary.disabled:active .gd-button-icon,
    .disabled.gd-button-link.gd-button-icon-only:active[class*="icon-"] .gd-button-icon,
    .disabled.gd-button-icon-only.gd-button-link-dimmed:active[class*="icon-"] .gd-button-icon,
    .gd-button-secondary.disabled::before,
    .gd-button-secondary.disabled::after,
    .gd-button-secondary.disabled .gd-button-icon,
    .gd-button-secondary.disabled:hover::before,
    .gd-button-secondary.disabled:hover::after,
    .gd-button-secondary.disabled:hover .gd-button-icon,
    .gd-button-secondary.disabled:focus::before,
    .gd-button-secondary.disabled:focus::after,
    .gd-button-secondary.disabled:focus .gd-button-icon,
    .gd-button-secondary.disabled:active::before,
    .gd-button-secondary.disabled:active::after,
    .gd-button-secondary.disabled:active .gd-button-icon {
      color: #b0beca; }

.gd-button-positive.disabled {
  cursor: default; }
  .gd-button-positive.disabled, .gd-button-positive.disabled:hover, .gd-button-positive.disabled:focus, .gd-button-positive.disabled:active {
    color: #fff;
    background: rgba(0, 224, 163, 0.5); }
    .gd-button-positive.disabled::before, .gd-button-positive.disabled::after,
    .gd-button-positive.disabled .gd-button-icon, .gd-button-positive.disabled:hover::before, .gd-button-positive.disabled:hover::after,
    .gd-button-positive.disabled:hover .gd-button-icon, .gd-button-positive.disabled:focus::before, .gd-button-positive.disabled:focus::after,
    .gd-button-positive.disabled:focus .gd-button-icon, .gd-button-positive.disabled:active::before, .gd-button-positive.disabled:active::after,
    .gd-button-positive.disabled:active .gd-button-icon {
      color: #fff; }

.gd-button-negative.disabled {
  cursor: default; }
  .gd-button-negative.disabled, .gd-button-negative.disabled:hover, .gd-button-negative.disabled:focus, .gd-button-negative.disabled:active {
    color: #fff;
    background: rgba(241, 161, 156, 0.6); }
    .gd-button-negative.disabled::before, .gd-button-negative.disabled::after,
    .gd-button-negative.disabled .gd-button-icon, .gd-button-negative.disabled:hover::before, .gd-button-negative.disabled:hover::after,
    .gd-button-negative.disabled:hover .gd-button-icon, .gd-button-negative.disabled:focus::before, .gd-button-negative.disabled:focus::after,
    .gd-button-negative.disabled:focus .gd-button-icon, .gd-button-negative.disabled:active::before, .gd-button-negative.disabled:active::after,
    .gd-button-negative.disabled:active .gd-button-icon {
      color: #fff; }

.gd-button-action.disabled {
  cursor: default; }
  .gd-button-action.disabled, .gd-button-action.disabled:hover, .gd-button-action.disabled:focus, .gd-button-action.disabled:active {
    color: #fff;
    background: rgba(69, 199, 239, 0.6);
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(enabled=false) "";
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false) ""; }
    .gd-button-action.disabled::before, .gd-button-action.disabled::after,
    .gd-button-action.disabled .gd-button-icon, .gd-button-action.disabled:hover::before, .gd-button-action.disabled:hover::after,
    .gd-button-action.disabled:hover .gd-button-icon, .gd-button-action.disabled:focus::before, .gd-button-action.disabled:focus::after,
    .gd-button-action.disabled:focus .gd-button-icon, .gd-button-action.disabled:active::before, .gd-button-action.disabled:active::after,
    .gd-button-action.disabled:active .gd-button-icon {
      color: #fff; }

.gd-button-link.disabled, .disabled.gd-button-link-dimmed {
  cursor: default; }
  .gd-button-link.disabled, .disabled.gd-button-link-dimmed, .gd-button-link.disabled:hover, .disabled.gd-button-link-dimmed:hover, .gd-button-link.disabled:focus, .disabled.gd-button-link-dimmed:focus, .gd-button-link.disabled:active, .disabled.gd-button-link-dimmed:active {
    color: #ccd8e2;
    border-color: transparent; }
    .gd-button-link.disabled > span, .disabled.gd-button-link-dimmed > span, .gd-button-link.disabled:hover > span, .disabled.gd-button-link-dimmed:hover > span, .gd-button-link.disabled:focus > span, .disabled.gd-button-link-dimmed:focus > span, .gd-button-link.disabled:active > span, .disabled.gd-button-link-dimmed:active > span {
      text-decoration: none; }
  .gd-button-link.disabled::before, .disabled.gd-button-link-dimmed::before, .gd-button-link.disabled::after, .disabled.gd-button-link-dimmed::after,
  .gd-button-link.disabled .gd-button-icon,
  .disabled.gd-button-link-dimmed .gd-button-icon {
    color: #ccd8e2; }

.gd-button-link.gd-button-icon-only[class*="icon-"]::before, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]::before, .gd-button-link.gd-button-icon-only[class*="icon-"]::after, .gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]::after {
  right: 0;
  left: 0;
  margin: 0 auto;
  padding: 0; }

.gd-button-group .gd-button {
  position: relative;
  z-index: 1;
  margin: 0 -1px 0 0;
  border-radius: 0; }
  .gd-button-group .gd-button:focus {
    z-index: 2; }
  .gd-button-group .gd-button:not(:focus) {
    box-shadow: none; }

.gd-button-group > .gd-button:first-child,
.gd-button-group > *:not(.gd-button):first-child .gd-button {
  border-radius: 3px 0 0 3px; }

.gd-button-group > .gd-button:last-child,
.gd-button-group > *:not(.gd-button):last-child .gd-button {
  margin-right: 0;
  border-radius: 0 3px 3px 0; }

/* Alternative markup support
   ========================================================================== */
a[class*="gd-button-"] {
  box-sizing: border-box;
  display: inline-block; }
  a[class*="gd-button-"], a[class*="gd-button-"]:hover, a[class*="gd-button-"]:focus {
    text-decoration: none; }
  a[class*="gd-button-"], a[class*="gd-button-"]:active {
    outline: none; }
  a[class*="gd-button-"] .gd-button-text {
    display: initial; }

a.gd-button-link:hover .gd-button-text, a.gd-button-link-dimmed:hover .gd-button-text, a.gd-button-link-dimmed:hover .gd-button-text {
  text-decoration: underline; }

.animation-fadeIn,
.animation-fadeOut,
.animation-bounce {
  animation-duration: 0.6s; }

.animation-fadeIn,
.animation-fadeOut {
  animation-name: animation-fade; }

.animation-fadeOut {
  animation-direction: reverse; }

.animation-bounce {
  animation-name: animation-bounce; }

.animation-slow {
  animation-duration: 1s; }

.animation-fast {
  animation-duration: 0.4s; }

@keyframes animation-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes animation-bounce {
  0% {
    opacity: 0;
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.gd-list-item.gd-list-item-header .is-collapsible::before, .gd-list-item.gd-list-item-header .is-open::before {
  overflow: hidden;
  width: 12px;
  height: 20px;
  margin: 1px 0 0;
  color: #b9b9b9;
  font-family: "Indigo";
  font-size: 11px; }

.gd-list-item.gd-menu-item.is-checked::before, .gd-list-item.gd-menu-item.is-submenu::after {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #b0beca;
  font-family: "Indigo"; }

.gd-list-noResults {
  padding: 15px;
  color: #94a1ad;
  font-size: 14px;
  font-family: avenir, "Helvetica Neue", arial, sans-serif;
  text-align: center; }

.gd-list-loading {
  margin: 10px;
  text-align: center; }

.gd-list-status-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  margin: 5px 10px;
  line-height: 20px;
  font-size: 12px;
  padding-top: 5px;
  border-top: 1px solid #dde4eb; }
  .gd-list-status-bar span {
    flex: 0 0 auto;
    height: 20px;
    white-space: nowrap;
    min-width: 0; }
  .gd-list-status-bar .gd-shortened-text {
    flex: 0 1 auto;
    overflow: hidden;
    width: auto;
    height: 20px;
    white-space: nowrap;
    max-width: inherit;
    text-overflow: ellipsis; }
    .gd-list-status-bar .gd-shortened-text.gd-selection-list {
      flex: 0 2 auto;
      font-weight: bold; }

.gd-list-actions {
  margin: 0 10px 10px;
  line-height: 1;
  font-size: 12px; }
  .gd-list-actions .gd-button-link:first-of-type, .gd-list-actions .gd-button-link-dimmed:first-of-type {
    margin-left: 0;
    padding-left: 0; }
  .gd-list-actions.gd-list-actions-invertable {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dde4eb; }
    .gd-list-actions.gd-list-actions-invertable .input-checkbox-label {
      flex: 0 1 auto;
      cursor: pointer; }
      .gd-list-actions.gd-list-actions-invertable .input-checkbox-label.disabled {
        cursor: default; }
      .gd-list-actions.gd-list-actions-invertable .input-checkbox-label::after {
        content: " "; }

.gd-list-actions-selection-size {
  box-sizing: border-box;
  margin: 1px 0 0 5px;
  color: #6d7680; }

.gd-list {
  line-height: 28px;
  font-size: 12px; }
  .gd-list .gd-infinite-list {
    position: relative;
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }

.gd-list-item {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  padding: 0 10px;
  color: #464e56;
  cursor: pointer;
  font-family: avenir, "Helvetica Neue", arial, sans-serif;
  font-size: 12px;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .gd-list-item:hover, .gd-list-item:focus {
    color: #000;
    background: #ebeff4;
    text-decoration: none; }
    .gd-list-item:hover.is-selected, .gd-list-item:focus.is-selected {
      background-color: #e8f7fc; }
  .gd-mobile-dropdown-content .gd-list-item {
    border-bottom: 1px solid #dde4eb;
    font-size: 14px;
    text-align: left; }
  .gd-list-item.is-disabled {
    opacity: 0.5; }
  .gd-list-item.is-selected {
    color: #14b2e2;
    font-weight: bold; }
    .gd-mobile-dropdown-content .gd-list-item.is-selected::after {
      content: "";
      position: absolute;
      top: 0;
      right: 13px;
      bottom: 0;
      display: block;
      width: 13px;
      background: url("~@gooddata/goodstrap/lib/./core/styles/images/checkmark.svg") no-repeat center; }
  .gd-list-item.gd-menu-item {
    padding: 4px 30px; }
    .gd-menu-wrapper.gd-menu-wrapper-small-spacing .gd-list-item.gd-menu-item {
      padding: 4px 10px; }
    .gd-list-item.gd-menu-item.is-checked::before {
      content: "\E62C";
      left: 0;
      width: 30px;
      font-size: 12px; }
    .gd-list-item.gd-menu-item.is-submenu::after {
      content: "\E611";
      right: 0;
      width: 30px;
      font-size: 14px; }
  .gd-list-item label.input-checkbox-label {
    pointer-events: none; }
  .gd-list-item input[type="checkbox"] {
    flex: 0 0 auto;
    margin: 0 7px 0 0; }
  .gd-list-item span,
  .gd-list-item label {
    display: block;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis; }
  .gd-list-item .gd-list-icon {
    flex: 0 0 auto; }
  .is-multiselect .gd-list-item.is-selected {
    color: #464e56;
    font-weight: bold; }
    .is-multiselect .gd-list-item.is-selected::after {
      display: none !important; }
  .is-multiselect .gd-list-item.is-focused.is-selected, .is-multiselect .gd-list-item:hover.is-selected {
    color: #464e56; }
  .gd-list-item.has-only-visible:hover:hover .gd-list-item-only {
    display: block; }
  .gd-list-item .gd-list-item-only {
    display: none;
    flex: 0 0 auto;
    color: #14b2e2;
    margin-left: 7px;
    margin-right: 12px;
    font-weight: normal; }
    .gd-list-item .gd-list-item-only:hover, .gd-list-item .gd-list-item-only:focus, .gd-list-item .gd-list-item-only:active {
      color: #464e56;
      text-decoration: underline; }
  .gd-list-item.gd-list-item-separator, .gd-list-item.gd-list-item-header {
    cursor: default; }
    .gd-list-item.gd-list-item-separator:hover,
    .gd-list-item.gd-list-item-separator:hover > div, .gd-list-item.gd-list-item-separator:focus,
    .gd-list-item.gd-list-item-separator:focus > div, .gd-list-item.gd-list-item-separator:active,
    .gd-list-item.gd-list-item-separator:active > div, .gd-list-item.gd-list-item-header:hover,
    .gd-list-item.gd-list-item-header:hover > div, .gd-list-item.gd-list-item-header:focus,
    .gd-list-item.gd-list-item-header:focus > div, .gd-list-item.gd-list-item-header:active,
    .gd-list-item.gd-list-item-header:active > div {
      color: #94a1ad;
      background: transparent; }
  .gd-list-item.gd-list-item-separator {
    position: relative;
    min-height: 10px; }
    .gd-list-item.gd-list-item-separator::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 10px;
      left: 10px;
      height: 0;
      border-bottom: 1px solid #dde4eb; }
      .gd-menu-wrapper .gd-list-item.gd-list-item-separator::before {
        right: 30px;
        left: 30px; }
      .gd-menu-wrapper.gd-menu-wrapper-small-spacing .gd-list-item.gd-list-item-separator::before {
        right: 10px;
        left: 10px; }
  .gd-list-item.gd-list-item-header {
    padding: 12px 10px 0;
    line-height: 16px;
    color: #94a1ad;
    font-size: 10px;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .gd-menu-wrapper .gd-list-item.gd-list-item-header {
      padding: 12px 30px 0; }
    .gd-menu-wrapper.gd-menu-wrapper-small-spacing .gd-list-item.gd-list-item-header {
      padding: 12px 10px 0; }
    .gd-list-item.gd-list-item-header > div {
      color: inherit; }
    .gd-list-item.gd-list-item-header .is-collapsible {
      cursor: pointer; }
      .gd-list-item.gd-list-item-header .is-collapsible:hover {
        background-color: #f2f2f2; }
      .gd-list-item.gd-list-item-header .is-collapsible::before {
        content: "\29";
        float: left; }
    .gd-list-item.gd-list-item-header .is-open::before {
      content: "\28"; }

.gd-list-icon {
  width: 16px;
  height: 16px;
  margin: 0 8px;
  line-height: 16px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }
  .gd-list-icon:first-child {
    margin-left: 0; }
  .gd-list-icon:last-child {
    margin-right: 0; }

.gd-list-item .input-checkbox-label,
.gd-list-actions .input-checkbox-label {
  padding-right: 0;
  padding-left: 23px; }

.gd-list-root .gd-button-link, .gd-list-root .gd-button-link-dimmed {
  padding: 0;
  font-size: 12px; }
  .gd-list-root .gd-button-link > span, .gd-list-root .gd-button-link-dimmed > span {
    text-decoration: none; }
  .gd-list-root .gd-button-link:hover > span, .gd-list-root .gd-button-link-dimmed:hover > span {
    text-decoration: underline; }
  .gd-list-root .gd-button-link:first-child, .gd-list-root .gd-button-link-dimmed:first-child {
    margin-left: 8px; }

.gd-infinite-list .fixedDataTableLayout_topShadow,
.gd-infinite-list .fixedDataTableLayout_bottomShadow {
  display: none; }

.gd-infinite-list .public_fixedDataTable_main,
.gd-infinite-list .fixedDataTableCellLayout_main,
.gd-infinite-list .fixedDataTableRowLayout_main {
  border: none; }

.gd-infinite-list .public_fixedDataTableRow_main,
.gd-infinite-list .public_fixedDataTableCell_main,
.gd-infinite-list .public_fixedDataTableRow_highlighted,
.gd-infinite-list .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
  background-color: transparent; }

.gd-infinite-list .public_fixedDataTableCell_cellContent {
  padding: 0;
  white-space: nowrap;
  color: #464e56; }
  .gd-infinite-list .public_fixedDataTableCell_cellContent:hover {
    background-color: transparent; }

.gd-infinite-list .fixedDataTableCellLayout_wrap1 {
  table-layout: fixed; }

.gd-infinite-list .fixedDataTableCellLayout_wrap2,
.gd-infinite-list .fixedDataTableCellLayout_wrap3 {
  width: inherit; }

.gd-infinite-list .fixedDataTableCellLayout_wrap2,
.gd-infinite-list .fixedDataTableCellLayout_wrap3,
.gd-infinite-list .public_fixedDataTableCell_cellContent,
.gd-infinite-list .gd-project-list-item {
  height: inherit; }

.gd-list-header,
.gd-list-footer {
  margin: 10px;
  line-height: 16px;
  color: #94a1ad;
  font-size: 12px; }

.gd-list-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #dde4eb; }

.gd-list-footer {
  padding-top: 10px;
  border-top: 1px solid #dde4eb; }

.gd-list-limitExceeded {
  margin: 5px 10px; }
  .gd-list-limitExceeded.gd-message::after {
    display: none; }

.gd-menu-wrapper {
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 8px 0;
  box-shadow: 0 1px 20px rgba(20, 56, 93, 0.2);
  background: #fff;
  border-radius: 3px; }

/* ==========================================================================
   Variables, mixins, silent classes etc.
   ========================================================================== */
.gd-dropdown.overlay {
  padding: 0; }

.gd-dropdown-search {
  margin: 8px;
  vertical-align: baseline; }

.gd-dropdown-buttons {
  padding: 8px;
  text-align: right; }
  .gd-dropdown-buttons .gd-button {
    margin-left: 10px; }

.gd-dropdown .gd-input-label {
  margin-bottom: 5px; }

.gd-dropdown .gd-list,
.gd-dropdown .gd-list-view,
.gd-dropdown .gd-infinite-list,
.dropdown-body .gd-list,
.dropdown-body .gd-list-view,
.dropdown-body .gd-infinite-list {
  margin: 6px 0; }
  @media only screen and (max-width: 40em) {
    .gd-dropdown .gd-list,
    .gd-dropdown .gd-list-view,
    .gd-dropdown .gd-infinite-list,
    .dropdown-body .gd-list,
    .dropdown-body .gd-list-view,
    .dropdown-body .gd-infinite-list {
      margin: 0; } }

.gd-dropdown .gd-list-view,
.gd-dropdown-small .gd-list-view {
  font-size: 12px; }

.gd-dropdown .gd-list-view-item,
.gd-dropdown .gd-list-view-item-header,
.gd-dropdown-small .gd-list-view-item,
.gd-dropdown-small .gd-list-view-item-header {
  height: 25px; }
  .gd-dropdown .gd-list-view-item > div,
  .gd-dropdown .gd-list-view-item-header > div,
  .gd-dropdown-small .gd-list-view-item > div,
  .gd-dropdown-small .gd-list-view-item-header > div {
    line-height: 25px; }

.gd-dropdown-normal .gd-list-view {
  font-size: 14px; }

.gd-dropdown-normal .gd-list-view-item,
.gd-dropdown-normal .gd-list-view-item-header {
  height: 28px; }
  .gd-dropdown-normal .gd-list-view-item > div,
  .gd-dropdown-normal .gd-list-view-item-header > div {
    line-height: 28px; }

.gd-no-data {
  margin: 12px;
  color: #94a1ad;
  text-align: center; }

.gd-no-data,
.gd-no-data-headline {
  line-height: 19px;
  font-size: 14px; }

.gd-no-data-text {
  line-height: 16px;
  font-size: 12px; }
  .gd-no-data-headline + .gd-no-data-text {
    margin-top: 13px; }

.gd-list-searchfield,
.gd-dropdown-searchfield {
  margin: 10px; }

.gd-dropdown-tabs {
  margin: 0 10px; }

.gd-mobile-dropdown-overlay {
  height: 100%;
  background-color: #f8fafb; }
  .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-button,
  .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-header-project {
    margin: 0;
    box-shadow: 0 1px 2px 0 rgba(20, 56, 93, 0.1);
    color: #000;
    padding-right: 30px;
    border-bottom: 1px solid #c9d5e0; }
    .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-button.is-expanded::after, .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-button.is-collapsed::after,
    .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-header-project.is-expanded::after,
    .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-header-project.is-collapsed::after {
      right: 10px;
      color: #464e56;
      opacity: 1; }
    .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-button.is-expanded, .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-button.is-expanded:hover, .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-button.is-collapsed, .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-button.is-collapsed:hover,
    .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-header-project.is-expanded,
    .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-header-project.is-expanded:hover,
    .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-header-project.is-collapsed,
    .gd-mobile-dropdown-overlay .gd-mobile-dropdown-header .gd-header-project.is-collapsed:hover {
      background-color: #fff; }

/* ==========================================================================
   Variables, mixins, silent classes etc.
   ========================================================================== */
/* ==========================================================================
   Bubble
   ========================================================================== */
.bubbleArrowShift {
  width: 7px;
  height: 12px; }

.inlineBubbleHelp {
  display: inline-block;
  font: 14px/1 "Indigo";
  vertical-align: middle;
  color: #bbb; }
  .inlineBubbleHelp::before {
    content: "\E60B"; }
  .inlineBubbleHelp, .inlineBubbleHelp:hover, .inlineBubbleHelp:focus, .inlineBubbleHelp:active {
    text-decoration: none; }
  .inlineBubbleHelp:hover, .inlineBubbleHelp:focus, .inlineBubbleHelp:active {
    color: #999;
    cursor: help; }

.bubble {
  position: absolute;
  max-width: 300px;
  -moz-transition-property: opacity;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out; }
  .bubble.bubble-small {
    max-width: 200px; }
  .bubble.gd-bubble {
    position: relative; }
  .bubble.isActive {
    opacity: 1; }
  .bubble .helper {
    position: absolute;
    background: white;
    opacity: 0.01; }
  .bubble .bubble-content {
    position: relative;
    overflow: visible;
    padding: 7px 10px;
    border-radius: 3px; }
    .bubble .bubble-content .content {
      display: block;
      overflow: hidden;
      max-width: 100%;
      word-wrap: break-word; }
      .bubble .bubble-content .content a {
        color: #fff;
        opacity: 0.8;
        text-decoration: underline;
        -moz-transition-property: all;
        -webkit-transition-property: all;
        transition-property: all;
        -moz-transition-duration: 0.25s;
        -webkit-transition-duration: 0.25s;
        transition-duration: 0.25s;
        -moz-transition-timing-function: ease-in-out;
        -webkit-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out; }
        .bubble .bubble-content .content a:hover {
          opacity: 1; }
      .bubble .bubble-content .content,
      .bubble .bubble-content .content p,
      .bubble .bubble-content .content a {
        font: 400 12px/18px avenir, "Helvetica Neue", arial, sans-serif; }
      .bubble .bubble-content .content p {
        margin: 0; }
      .bubble .bubble-content .content p + p {
        margin-top: 0.5em; }
  .bubble.bubble-primary {
    padding: 0;
    border-radius: 3px;
    border-style: solid;
    border-width: 0;
    border-color: rgba(70, 78, 86, 0.95);
    box-shadow: 0 1px 3px 0 rgba(20, 56, 93, 0.10), 0 2px 9px 0 rgba(20, 56, 93, 0.15); }
  .bubble.bubble-primary .arrow-border {
    display: none; }
  .bubble.bubble-primary {
    color: #fff;
    background: rgba(70, 78, 86, 0.95); }
    .bubble.bubble-primary .arrow-border,
    .bubble.bubble-primary .arrow {
      border-color: transparent; }
  .bubble.bubble-primary.arrow-bottom-direction .arrow-border {
    border-top-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-primary.arrow-bottom-direction .arrow {
    border-top-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-primary.arrow-top-direction .arrow-border {
    border-bottom-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-primary.arrow-top-direction .arrow {
    border-bottom-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-primary.arrow-left-direction .arrow-border {
    border-right-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-primary.arrow-left-direction .arrow {
    border-right-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-primary.arrow-right-direction .arrow-border {
    border-left-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-primary.arrow-right-direction .arrow {
    border-left-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-secondary {
    padding: 0;
    border-radius: 3px;
    border-style: solid;
    border-width: 0;
    border-color: rgba(20, 178, 226, 0.9);
    box-shadow: 0 1px 3px 0 rgba(20, 56, 93, 0.10), 0 2px 9px 0 rgba(20, 56, 93, 0.15); }
  .bubble.bubble-secondary .arrow-border {
    display: none; }
  .bubble.bubble-secondary {
    color: #fff;
    background: rgba(20, 178, 226, 0.9); }
    .bubble.bubble-secondary .arrow-border,
    .bubble.bubble-secondary .arrow {
      border-color: transparent; }
  .bubble.bubble-secondary.arrow-bottom-direction .arrow-border {
    border-top-color: rgba(20, 178, 226, 0.9); }
  .bubble.bubble-secondary.arrow-bottom-direction .arrow {
    border-top-color: rgba(20, 178, 226, 0.9); }
  .bubble.bubble-secondary.arrow-top-direction .arrow-border {
    border-bottom-color: rgba(20, 178, 226, 0.9); }
  .bubble.bubble-secondary.arrow-top-direction .arrow {
    border-bottom-color: rgba(20, 178, 226, 0.9); }
  .bubble.bubble-secondary.arrow-left-direction .arrow-border {
    border-right-color: rgba(20, 178, 226, 0.9); }
  .bubble.bubble-secondary.arrow-left-direction .arrow {
    border-right-color: rgba(20, 178, 226, 0.9); }
  .bubble.bubble-secondary.arrow-right-direction .arrow-border {
    border-left-color: rgba(20, 178, 226, 0.9); }
  .bubble.bubble-secondary.arrow-right-direction .arrow {
    border-left-color: rgba(20, 178, 226, 0.9); }
  .bubble.bubble-dark {
    padding: 0;
    border-radius: 3px;
    border-style: solid;
    border-width: 0;
    border-color: rgba(70, 78, 86, 0.95);
    box-shadow: 0 1px 3px 0 rgba(20, 56, 93, 0.10), 0 2px 9px 0 rgba(20, 56, 93, 0.15); }
  .bubble.bubble-dark .arrow-border {
    display: none; }
  .bubble.bubble-dark {
    color: #fff;
    background: rgba(70, 78, 86, 0.95); }
    .bubble.bubble-dark .arrow-border,
    .bubble.bubble-dark .arrow {
      border-color: transparent; }
  .bubble.bubble-dark.arrow-bottom-direction .arrow-border {
    border-top-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-dark.arrow-bottom-direction .arrow {
    border-top-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-dark.arrow-top-direction .arrow-border {
    border-bottom-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-dark.arrow-top-direction .arrow {
    border-bottom-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-dark.arrow-left-direction .arrow-border {
    border-right-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-dark.arrow-left-direction .arrow {
    border-right-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-dark.arrow-right-direction .arrow-border {
    border-left-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-dark.arrow-right-direction .arrow {
    border-left-color: rgba(70, 78, 86, 0.95); }
  .bubble.bubble-light {
    padding: 0;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #c9d5e0;
    box-shadow: 0 1px 3px 0 rgba(20, 56, 93, 0.10), 0 2px 9px 0 rgba(20, 56, 93, 0.15); }
  .bubble.bubble-light {
    color: #000;
    background: rgba(255, 255, 255, 0.95); }
    .bubble.bubble-light .arrow-border,
    .bubble.bubble-light .arrow {
      border-color: transparent; }
  .bubble.bubble-light.arrow-bottom-direction .arrow-border {
    border-top-color: #c9d5e0; }
  .bubble.bubble-light.arrow-bottom-direction .arrow {
    border-top-color: rgba(255, 255, 255, 0.95); }
  .bubble.bubble-light.arrow-top-direction .arrow-border {
    border-bottom-color: #c9d5e0; }
  .bubble.bubble-light.arrow-top-direction .arrow {
    border-bottom-color: rgba(255, 255, 255, 0.95); }
  .bubble.bubble-light.arrow-left-direction .arrow-border {
    border-right-color: #c9d5e0; }
  .bubble.bubble-light.arrow-left-direction .arrow {
    border-right-color: rgba(255, 255, 255, 0.95); }
  .bubble.bubble-light.arrow-right-direction .arrow-border {
    border-left-color: #c9d5e0; }
  .bubble.bubble-light.arrow-right-direction .arrow {
    border-left-color: rgba(255, 255, 255, 0.95); }
  .bubble.bubble-negative {
    padding: 0;
    border-radius: 3px;
    border-style: solid;
    border-width: 0;
    border-color: rgba(229, 77, 66, 0.9);
    box-shadow: 0 1px 3px 0 rgba(20, 56, 93, 0.10), 0 2px 9px 0 rgba(20, 56, 93, 0.15); }
  .bubble.bubble-negative .arrow-border {
    display: none; }
  .bubble.bubble-negative {
    color: #fff;
    background: rgba(229, 77, 66, 0.9); }
    .bubble.bubble-negative .arrow-border,
    .bubble.bubble-negative .arrow {
      border-color: transparent; }
  .bubble.bubble-negative.arrow-bottom-direction .arrow-border {
    border-top-color: rgba(229, 77, 66, 0.9); }
  .bubble.bubble-negative.arrow-bottom-direction .arrow {
    border-top-color: rgba(229, 77, 66, 0.9); }
  .bubble.bubble-negative.arrow-top-direction .arrow-border {
    border-bottom-color: rgba(229, 77, 66, 0.9); }
  .bubble.bubble-negative.arrow-top-direction .arrow {
    border-bottom-color: rgba(229, 77, 66, 0.9); }
  .bubble.bubble-negative.arrow-left-direction .arrow-border {
    border-right-color: rgba(229, 77, 66, 0.9); }
  .bubble.bubble-negative.arrow-left-direction .arrow {
    border-right-color: rgba(229, 77, 66, 0.9); }
  .bubble.bubble-negative.arrow-right-direction .arrow-border {
    border-left-color: rgba(229, 77, 66, 0.9); }
  .bubble.bubble-negative.arrow-right-direction .arrow {
    border-left-color: rgba(229, 77, 66, 0.9); }
  .bubble .arrow-position,
  .bubble .arrow-border,
  .bubble .arrow {
    position: absolute;
    z-index: 2;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px; }
  .bubble .arrow-position {
    border-width: 0; }
  .bubble .arrow {
    z-index: 4;
    border-width: 6px; }
  .bubble.arrow-top-direction .arrow-position, .bubble.arrow-bottom-direction .arrow-position {
    margin: 0 12px; }
  .bubble.arrow-top-direction .arrow, .bubble.arrow-bottom-direction .arrow {
    left: -6px; }
  .bubble.arrow-top-direction .arrow-border, .bubble.arrow-bottom-direction .arrow-border {
    top: 1px;
    left: -7px; }
  .bubble.arrow-top-direction .helper, .bubble.arrow-bottom-direction .helper {
    right: 0;
    left: 0;
    height: 8px; }
  .bubble.arrow-top-direction.arrow-tl .arrow-position, .bubble.arrow-top-direction.arrow-bl .arrow-position, .bubble.arrow-bottom-direction.arrow-tl .arrow-position, .bubble.arrow-bottom-direction.arrow-bl .arrow-position {
    left: 0; }
  .bubble.arrow-top-direction.arrow-tr .arrow-position, .bubble.arrow-top-direction.arrow-br .arrow-position, .bubble.arrow-bottom-direction.arrow-tr .arrow-position, .bubble.arrow-bottom-direction.arrow-br .arrow-position {
    right: 0; }
  .bubble.arrow-top-direction.arrow-tc .arrow-position, .bubble.arrow-top-direction.arrow-bc .arrow-position, .bubble.arrow-bottom-direction.arrow-tc .arrow-position, .bubble.arrow-bottom-direction.arrow-bc .arrow-position {
    right: 0;
    left: 0;
    margin: 0 auto; }
  .bubble.arrow-top-direction .arrow-position {
    top: -15px; }
  .bubble.arrow-top-direction .arrow {
    top: 3px; }
  .bubble.arrow-top-direction .helper {
    top: -8px; }
  .bubble.arrow-bottom-direction .arrow-position {
    bottom: 0; }
  .bubble.arrow-bottom-direction .arrow-border {
    top: 0; }
  .bubble.arrow-bottom-direction .helper {
    bottom: -8px; }
  .bubble.arrow-left-direction .arrow-position, .bubble.arrow-right-direction .arrow-position {
    margin: 10px 0; }
  .bubble.arrow-left-direction .arrow, .bubble.arrow-right-direction .arrow {
    top: -5px; }
  .bubble.arrow-left-direction .arrow-border, .bubble.arrow-right-direction .arrow-border {
    top: -6px;
    left: 2px; }
  .bubble.arrow-left-direction .helper, .bubble.arrow-right-direction .helper {
    top: 0;
    bottom: 0;
    width: 8px; }
  .bubble.arrow-left-direction.arrow-tl .arrow-position, .bubble.arrow-left-direction.arrow-tr .arrow-position, .bubble.arrow-right-direction.arrow-tl .arrow-position, .bubble.arrow-right-direction.arrow-tr .arrow-position {
    top: 0; }
  .bubble.arrow-left-direction.arrow-bl .arrow-position, .bubble.arrow-left-direction.arrow-br .arrow-position, .bubble.arrow-right-direction.arrow-bl .arrow-position, .bubble.arrow-right-direction.arrow-br .arrow-position {
    bottom: 3px; }
  .bubble.arrow-left-direction.arrow-cl .arrow-position, .bubble.arrow-left-direction.arrow-cr .arrow-position, .bubble.arrow-right-direction.arrow-cl .arrow-position, .bubble.arrow-right-direction.arrow-cr .arrow-position {
    top: 0;
    bottom: 0;
    margin: auto 0; }
  .bubble.arrow-left-direction .arrow-position {
    left: -16px; }
  .bubble.arrow-left-direction .arrow {
    left: 4px; }
  .bubble.arrow-left-direction .helper {
    left: -8px; }
  .bubble.arrow-right-direction .arrow-position {
    right: 0; }
  .bubble.arrow-right-direction .arrow-border {
    left: 0; }
  .bubble.arrow-right-direction .helper {
    right: -8px; }
  .bubble.arrow-none .arrow-position {
    display: none; }

.gecko .bubble-chart,
.chrome .bubble-chart,
.safari .bubble-chart {
  opacity: 0; }

.gecko .bubble-anim,
.chrome .bubble-anim,
.safari .bubble-anim {
  opacity: 1;
  -moz-transition-property: opacity, transform;
  -webkit-transition-property: opacity, transform;
  transition-property: opacity, transform;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out; }

.gecko .bubble-anim.yui3-overlay-hidden,
.chrome .bubble-anim.yui3-overlay-hidden,
.safari .bubble-anim.yui3-overlay-hidden {
  visibility: visible !important;
  -moz-transition-duration: 0s;
  opacity: 0; }

.bubble-none {
  display: none; }

.indigo-table-component {
  position: relative;
  overflow: hidden;
  font-family: "Avenir", "Helvetica Neue", Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 100%;
  color: #333; }
  .indigo-table-component.has-hidden-rows:not(.has-footer) .fixedDataTableLayout_rowsContainer::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 13px;
    left: 0;
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.03); }
  .indigo-table-component.has-hidden-rows .indigo-table-component-content,
  .indigo-table-component.has-hidden-rows .indigo-table-background-filler {
    margin-bottom: -15px; }
  .indigo-table-component.has-hidden-rows .public_fixedDataTable_horizontalScrollbar {
    transform: translateY(-15px); }
  .indigo-table-component .indigo-table-component-content {
    width: 100%;
    height: 100%; }
    .indigo-table-component .indigo-table-component-content > div,
    .indigo-table-component .indigo-table-component-content .react-table-root {
      height: 100%; }
    .indigo-table-component .indigo-table-component-content.has-sticky-header {
      position: absolute;
      clip: rect(0, auto, auto, 0); }
    .indigo-table-component .indigo-table-component-content .table-header,
    .indigo-table-component .indigo-table-component-content .table-footer {
      box-shadow: none;
      transition: box-shadow 0.3s ease-in-out; }
    .indigo-table-component .indigo-table-component-content .table-header.sticking {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15); }
    .indigo-table-component .indigo-table-component-content .table-footer {
      z-index: 2 !important; }
      .indigo-table-component .indigo-table-component-content .table-footer.sticking {
        box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.15); }
    .indigo-table-component .indigo-table-component-content .ScrollbarLayout_mainHorizontal {
      border-left: 0; }
  .indigo-table-component .fixedDataTableLayout_main,
  .indigo-table-component .fixedDataTableCellLayout_main {
    border: none;
    background: none; }
  .indigo-table-component .fixedDataTableRowLayout_main {
    background-color: #f5f7f9; }
  .indigo-table-component .public_fixedDataTableRow_highlighted .fixedDataTableCellLayout_main {
    background-color: #fff; }
  .indigo-table-component .public_fixedDataTable_bodyRow .indigo-table-cell-highlight {
    background: rgba(20, 178, 226, 0.1); }
  .indigo-table-component .fixedDataTableCellLayout_main,
  .indigo-table-component .fixedDataTableLayout_topShadow,
  .indigo-table-component .fixedDataTableLayout_bottomShadow {
    background: none; }
  .indigo-table-component .fixedDataTableLayout_topShadow {
    height: 100px;
    margin-top: -100px;
    box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.5);
    pointer-events: none; }
  .indigo-table-component .fixedDataTableLayout_bottomShadow {
    margin-top: 0;
    box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.21); }
  .indigo-table-component .fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main {
    white-space: nowrap; }
  .indigo-table-component .public_fixedDataTableCell_cellContent {
    display: block;
    padding: 0; }
  .indigo-table-component .gd-cell {
    display: block; }
  .indigo-table-component .gd-cell,
  .indigo-table-component .public_fixedDataTable_header .public_fixedDataTableCell_cellContent {
    overflow: hidden;
    padding: 8px;
    text-overflow: ellipsis; }
  .indigo-table-component .fixedDataTableCellGroupLayout_cellGroup {
    overflow: hidden;
    text-overflow: ellipsis; }
  .indigo-table-component .fixedDataTableCellLayout_main:last-child .public_fixedDataTableCell_cellContent .gd-cell,
  .indigo-table-component .fixedDataTableCellLayout_main:last-child .public_fixedDataTableCell_cellContent .indigo-table-footer-cell {
    padding-right: 20px; }
  .indigo-table-component .fixedDataTableLayout_header,
  .indigo-table-component .fixedDataTableLayout_hasBottomBorder {
    border: none; }
  .indigo-table-component .fixedDataTableLayout_header,
  .indigo-table-component .fixedDataTableLayout_footer {
    padding: 0;
    box-shadow: none;
    white-space: nowrap;
    background: #fff; }
  .indigo-table-component .fixedDataTableLayout_header .gd-table-header-ordering {
    color: #6d7680;
    cursor: pointer; }
    .indigo-table-component .fixedDataTableLayout_header .gd-table-header-ordering:hover {
      color: #14b2e2; }
      .indigo-table-component .fixedDataTableLayout_header .gd-table-header-ordering:hover .public_fixedDataTableCell_cellContent {
        background-color: #e8f7fc; }
    .indigo-table-component .fixedDataTableLayout_header .gd-table-header-ordering .public_fixedDataTableCell_cellContent {
      display: flex; }
    .indigo-table-component .fixedDataTableLayout_header .gd-table-header-ordering .gd-table-arrow-up,
    .indigo-table-component .fixedDataTableLayout_header .gd-table-header-ordering .gd-table-arrow-down {
      display: inline-block;
      flex: 0 0 auto;
      margin-left: 4px; }
      .indigo-table-component .fixedDataTableLayout_header .gd-table-header-ordering .gd-table-arrow-up::before,
      .indigo-table-component .fixedDataTableLayout_header .gd-table-header-ordering .gd-table-arrow-down::before {
        content: "\e60d";
        position: relative;
        top: 1px;
        display: inherit;
        font-family: Indigo;
        font-size: 10px;
        vertical-align: bottom; }
    .indigo-table-component .fixedDataTableLayout_header .gd-table-header-ordering .gd-table-arrow-up::before {
      top: -2px;
      transform: rotate(180deg); }
    .indigo-table-component .fixedDataTableLayout_header .gd-table-header-ordering .gd-table-header-title {
      display: inline-block;
      flex: 0 1 auto;
      overflow: hidden;
      text-overflow: ellipsis; }
      .indigo-table-component .fixedDataTableLayout_header .gd-table-header-ordering .gd-table-header-title::before {
        content: "";
        display: block; }
  .indigo-table-component .fixedDataTableLayout_header .fixedDataTableCellLayout_main {
    font-size: 12px;
    font-weight: normal;
    color: #94a1ad;
    background: none; }
    .indigo-table-component .fixedDataTableLayout_header .fixedDataTableCellLayout_main:last-child .public_fixedDataTableCell_cellContent {
      padding-right: 20px; }
  .indigo-table-component .fixedDataTableLayout_header .public_fixedDataTableCell_cellContent {
    margin: 8px 0;
    padding: 0 8px;
    line-height: 16px; }
  .indigo-table-component .fixedDataTableCellLayout_wrap1 {
    width: inherit;
    table-layout: fixed; }
  .indigo-table-component .fixedDataTableCellLayout_alignRight .gd-table-header-ordering .gd-table-header-title {
    flex: 1 1 auto; }
  .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
    background: none; }
    .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .gd-cell {
      padding: 0; }
    .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .indigo-table-footer-cell {
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
      padding: 0 8px;
      border-bottom: 1px solid #e2e8ed;
      font-size: 12px;
      line-height: 30px;
      text-transform: none;
      text-overflow: ellipsis; }
      .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .indigo-table-footer-cell:first-child {
        border-top: 1px solid #b0beca; }
    .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .indigo-totals-add-cell {
      border-bottom: none; }
    .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .indigo-totals-add-wrapper {
      height: 41px; }
    .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .indigo-totals-enable-column-button {
      margin-right: -8px; }
    .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .indigo-totals-disable-column-button {
      max-width: 23px; }
    .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .indigo-totals-disable-column-button,
    .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .indigo-totals-enable-column-button {
      display: none; }
      .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .indigo-totals-disable-column-button::before,
      .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .indigo-totals-enable-column-button::before {
        width: 18px;
        height: 18px;
        font-size: 15px;
        line-height: 14px; }
    .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .indigo-table-footer-cell:hover .indigo-totals-disable-column-button,
    .indigo-table-component .fixedDataTableLayout_footer .fixedDataTableCellLayout_main .indigo-table-footer-cell:hover .indigo-totals-enable-column-button {
      display: inline-block; }
  .indigo-table-component .indigo-totals-add-row-button {
    position: absolute;
    top: 3px;
    right: 0;
    left: 0;
    display: none;
    height: 28px;
    padding: 5px 0;
    border-radius: 4px;
    line-height: 25px;
    text-align: center;
    cursor: pointer; }
    .indigo-table-component .indigo-totals-add-row-button.hidden {
      visibility: hidden; }
    .indigo-table-component .indigo-totals-add-row-button::before {
      content: "\03A3";
      display: inline-block;
      width: 26px;
      height: 26px;
      border: 1px solid #ccd8e2;
      border-radius: 50%;
      box-shadow: 0 1px 1px 0 rgba(20, 56, 93, 0.15);
      font-size: 16px;
      font-weight: normal;
      line-height: 27px;
      color: #b0beca;
      background-color: #fcfcfd; }
    .indigo-table-component .indigo-totals-add-row-button:hover::before {
      border-color: rgba(31, 52, 73, 0.2);
      box-shadow: 0 1px 1px 0 rgba(20, 56, 93, 0.15), inset 0 -1px 0 0 rgba(177, 193, 209, 0.6);
      background: #f5f8fa; }
    .indigo-table-component .indigo-totals-add-row-button:focus::before {
      border-color: rgba(20, 178, 226, 0.75);
      box-shadow: 0 0 3px 1px rgba(69, 199, 239, 0), 0 1px 2px 0 rgba(20, 56, 93, 0.15), inset 0 -1px 0 0 rgba(177, 193, 209, 0.6); }
    .indigo-table-component .indigo-totals-add-row-button::before, .indigo-table-component .indigo-totals-add-row-button:hover::before, .indigo-table-component .indigo-totals-add-row-button:focus::before, .indigo-table-component .indigo-totals-add-row-button:active::before, .indigo-table-component .indigo-totals-add-row-button.highlighted::before {
      background-repeat: no-repeat;
      background-position: center; }
    .indigo-table-component .indigo-totals-add-row-button:hover::before, .indigo-table-component .indigo-totals-add-row-button:focus::before, .indigo-table-component .indigo-totals-add-row-button:active::before, .indigo-table-component .indigo-totals-add-row-button.highlighted::before {
      color: #14b2e2; }
  .indigo-table-component .dropdown-active .indigo-totals-add-row-button::before,
  .indigo-table-component .indigo-totals-add-row-button:active::before {
    background: #ecf0f5;
    /* Old browsers */
    background-image: -webkit-linear-gradient(top, #dee6ef, #ecf0f5);
    /* Chrome 10+, Saf5.1+ */
    background-image: -moz-linear-gradient(top, #dee6ef, #ecf0f5);
    /* FF3.6 */
    background-image: -ms-linear-gradient(top, #dee6ef, #ecf0f5);
    /* IE10 */
    background-image: linear-gradient(to top, #dee6ef, #ecf0f5);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#FFDEE6EF', EndColorStr='#FFECF0F5');
    /* IE6-IE9 */
    border-color: #b1c1d1;
    box-shadow: inset 0 1px 0 0 rgba(177, 193, 209, 0.65);
    color: #14b2e2; }
  .indigo-table-component .indigo-totals-add-row-button.highlighted,
  .indigo-table-component .indigo-table-footer-cell-highlight .indigo-totals-add-row-button {
    display: block;
    background: rgba(20, 178, 226, 0.1); }

.indigo-totals-select-type-list::before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: -8px;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  transform: translateX(-50%) rotate(45deg); }

.indigo-totals-select-type-list::after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 10px;
  bottom: 0;
  left: 10px;
  display: block;
  height: 16px;
  background: #fff; }

.indigo-totals-select-type-list > div {
  position: relative;
  z-index: 3; }

.indigo-totals-select-type-list .indigo-totals-select-type-header {
  padding: 2px 10px 0; }

.indigo-totals-select-type-list .indigo-totals-select-type-item-disabled {
  color: #b0beca;
  background: none;
  cursor: default; }

.overlay-wrapper.self-br .indigo-totals-select-type-list::before {
  right: 8px;
  left: auto;
  margin: 0; }

.overlay-wrapper.self-tc .indigo-totals-select-type-list::before {
  top: -8px;
  box-shadow: -3px -3px 5px 0 rgba(0, 0, 0, 0.05); }

.overlay-wrapper.self-tr .indigo-totals-select-type-list::before {
  top: -8px;
  right: 8px;
  left: auto;
  margin: 0;
  box-shadow: -3px -3px 5px 0 rgba(0, 0, 0, 0.05); }

.indigo-totals-remove {
  position: absolute;
  z-index: 100;
  right: 0;
  bottom: 50px;
  width: 100%;
  padding-bottom: 2px;
  pointer-events: none; }
  .indigo-totals-remove .indigo-totals-remove-row {
    position: relative;
    width: 100%;
    height: 30px;
    box-shadow: none;
    transition: box-shadow 1s;
    pointer-events: none; }
    .indigo-totals-remove .indigo-totals-remove-row:hover .indigo-totals-row-remove-button, .indigo-totals-remove .indigo-totals-remove-row.indigo-totals-remove-row-highlight .indigo-totals-row-remove-button {
      visibility: visible; }
    .indigo-totals-remove .indigo-totals-remove-row.last-added {
      box-shadow: inset 0 0 0 1px #14b2e2, 0 0 2px 1px #14b2e2; }
    .indigo-totals-remove .indigo-totals-remove-row .indigo-totals-row-remove-button {
      position: absolute;
      z-index: 3;
      top: -13px;
      right: -6px;
      visibility: hidden;
      overflow: hidden;
      width: 25px;
      height: 25px;
      padding: 0 0 0 9px;
      border: none;
      border-radius: 10px;
      outline: none;
      background: transparent;
      cursor: pointer;
      pointer-events: auto; }
      .indigo-totals-remove .indigo-totals-remove-row .indigo-totals-row-remove-button::before {
        content: "\E639";
        display: block;
        overflow: hidden;
        width: 11px;
        height: 11px;
        padding: 0;
        border: 2px solid #14b2e2;
        border-radius: 50%;
        font-family: "Indigo";
        font-size: 16px;
        line-height: 13px;
        text-indent: -2px;
        color: #fff;
        background: #14b2e2; }
      .indigo-totals-remove .indigo-totals-remove-row .indigo-totals-row-remove-button:hover {
        visibility: visible; }
      .indigo-totals-remove .indigo-totals-remove-row .indigo-totals-row-remove-button:hover::before {
        border-color: #e54d42;
        background: #e54d42; }

.gdc-indigo-responsive-table .indigo-button-bar {
  width: 100%;
  padding: 20px 0 4px;
  text-align: center; }

.gdc-indigo-responsive-table button {
  margin: 0 4px; }

.gd-cell-drillable {
  font-weight: bold;
  color: #464e56;
  cursor: pointer; }
  .gd-cell-drillable:hover {
    text-decoration: underline; }

@media only screen and (max-width: 480px) {
  .gd-table-header-bubble-overlay {
    right: 20px !important;
    left: 20px !important; }
    .gd-table-header-bubble-overlay .gd-table-header-bubble.bubble.arrow-top-direction .arrow-position, .gd-table-header-bubble-overlay .gd-table-header-bubble.bubble.arrow-bottom-direction .arrow-position {
      margin: 0; } }

.gd-table-header-bubble.bubble {
  max-width: 500px; }
  @media only screen and (max-width: 480px) {
    .gd-table-header-bubble.bubble {
      max-width: none; } }
  .gd-table-header-bubble.bubble .bubble-content {
    padding: 14px 20px; }
    .gd-table-header-bubble.bubble .bubble-content .content {
      overflow: visible;
      color: #6d7680; }
  .gd-table-header-bubble.bubble.arrow-top-direction .arrow,
  .gd-table-header-bubble.bubble.arrow-top-direction .arrow-border, .gd-table-header-bubble.bubble.arrow-bottom-direction .arrow,
  .gd-table-header-bubble.bubble.arrow-bottom-direction .arrow-border {
    left: -7px;
    border-width: 10px; }
  .gd-table-header-bubble.bubble.arrow-top-direction .helper, .gd-table-header-bubble.bubble.arrow-bottom-direction .helper {
    height: 11px; }
  .gd-table-header-bubble.bubble.arrow-top-direction .helper {
    top: -11px; }
  .gd-table-header-bubble.bubble.arrow-top-direction .arrow {
    top: -4px; }
  .gd-table-header-bubble.bubble.arrow-top-direction .arrow-border {
    top: -5px; }
  .gd-table-header-bubble.bubble.arrow-bottom-direction .helper {
    bottom: -11px; }
  .gd-table-header-bubble.bubble.arrow-bottom-direction .arrow {
    top: -1px; }
  .gd-table-header-bubble.bubble.arrow-tr .arrow,
  .gd-table-header-bubble.bubble.arrow-tr .arrow-border, .gd-table-header-bubble.bubble.arrow-br .arrow,
  .gd-table-header-bubble.bubble.arrow-br .arrow-border {
    left: -13px; }
  .gd-table-header-bubble.bubble .content .close-button.gd-button-link, .gd-table-header-bubble.bubble .content .close-button.gd-button-link-dimmed {
    position: absolute;
    top: 12px;
    right: 12px; }
  .gd-table-header-bubble.bubble .content .gd-dialog-header {
    overflow: auto;
    max-height: 105px;
    margin: 0 -20px 10px;
    padding: 0 50px 10px 20px;
    border-bottom: 1px solid #dde4eb;
    white-space: normal; }
  .gd-table-header-bubble.bubble .buttons-wrap {
    margin-bottom: 5px; }
    .gd-table-header-bubble.bubble .buttons-wrap .buttons {
      display: flex;
      flex-wrap: wrap;
      margin-left: -20px; }
      .gd-table-header-bubble.bubble .buttons-wrap .buttons .gd-button {
        flex: 1 1 auto;
        margin-left: 20px;
        margin-top: 10px; }
    .gd-table-header-bubble.bubble .buttons-wrap .button-sort-asc,
    .gd-table-header-bubble.bubble .buttons-wrap .button-sort-desc {
      padding-right: 25px; }
      .gd-table-header-bubble.bubble .buttons-wrap .button-sort-asc::after,
      .gd-table-header-bubble.bubble .buttons-wrap .button-sort-desc::after {
        font-size: 10px;
        line-height: 23px; }
    .gd-table-header-bubble.bubble .buttons-wrap .gd-button.gd-button-primary.button-sort-asc::after, .gd-table-header-bubble.bubble .buttons-wrap .gd-button.button-sort-asc.gd-button-link.gd-button-icon-only[class*="icon-"]::after, .gd-table-header-bubble.bubble .buttons-wrap .gd-button.button-sort-asc.gd-button-icon-only.gd-button-link-dimmed[class*="icon-"]::after {
      transform: rotate(180deg) translateY(50%); }

.viz-line-family-chart-wrap {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%; }
  .viz-line-family-chart-wrap.flex-direction-row {
    flex-direction: row; }
  .viz-line-family-chart-wrap.flex-direction-column {
    flex-direction: column; }
  .viz-line-family-chart-wrap.non-responsive-legend .viz-react-highchart-wrap {
    min-height: 0; }

.viz-react-highchart-wrap {
  height: 100%;
  min-width: 0; }

.highcharts-container {
  overflow: visible !important;
  width: 100% !important; }
  .highcharts-container svg {
    width: 100% !important; }

.highcharts-drilldown-axis-label:hover {
  text-decoration: underline !important; }

.gd-axis-label-drilling-disabled .highcharts-drilldown-axis-label {
  text-decoration: none !important;
  font-weight: normal !important;
  color: #94a1ad !important;
  pointer-events: none;
  fill: #94a1ad !important; }

.hidden-empty-series {
  display: none; }

@media only screen and (max-width: 767px) {
  .viz-line-family-chart-wrap.responsive-legend {
    flex-direction: column !important; } }

.viz-legend-wrap {
  width: 100%;
  height: 100%; }

.viz-fluid-legend-wrap {
  flex: 1 1 auto; }

.viz-static-legend-wrap.position-top, .viz-static-legend-wrap.position-bottom {
  flex: 1 0 auto; }

.viz-static-legend-wrap.position-right, .viz-static-legend-wrap.position-left {
  flex: 0 0 210px; }

.viz-legend {
  display: flex; }
  .viz-legend .series {
    display: flex;
    width: 100%;
    text-align: left; }
  .viz-legend .series-item,
  .viz-legend .series-axis-indicator {
    display: flex;
    align-items: center;
    font-family: avenir, "Helvetica Neue", arial, sans-serif;
    cursor: pointer; }
    .viz-legend .series-item .series-icon,
    .viz-legend .series-axis-indicator .series-icon {
      flex: 0 0 auto;
      width: 9px;
      height: 9px;
      margin-right: 5px; }
    .viz-legend .series-item .series-name,
    .viz-legend .series-item .series-text,
    .viz-legend .series-axis-indicator .series-name,
    .viz-legend .series-axis-indicator .series-text {
      flex: 1 1 auto;
      font-size: 12px;
      line-height: 20px;
      color: #6d7680;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
  .viz-legend .series-axis-indicator {
    cursor: default;
    margin-right: -10px; }
    .viz-legend .series-axis-indicator .series-text {
      color: #b0beca; }
  .viz-legend .legend-separator {
    width: 20px;
    height: 15px; }
  .viz-legend .paging {
    white-space: nowrap; }
    .viz-legend .paging button.paging-button {
      padding: 0;
      border-radius: 50%; }
      .viz-legend .paging button.paging-button::before {
        font-size: 11px; }
      .viz-legend .paging button.paging-button, .viz-legend .paging button.paging-button:hover, .viz-legend .paging button.paging-button:focus, .viz-legend .paging button.paging-button:active {
        text-decoration: none;
        background-color: #eee; }
        .viz-legend .paging button.paging-button::before, .viz-legend .paging button.paging-button:hover::before, .viz-legend .paging button.paging-button:focus::before, .viz-legend .paging button.paging-button:active::before {
          color: #000; }
      .viz-legend .paging button.paging-button:hover::before {
        color: #14b2e2; }
      .viz-legend .paging button.paging-button[disabled], .viz-legend .paging button.paging-button[disabled]:hover, .viz-legend .paging button.paging-button[disabled]:focus, .viz-legend .paging button.paging-button[disabled]:active {
        background-color: rgba(238, 238, 238, 0.5); }
        .viz-legend .paging button.paging-button[disabled]::before, .viz-legend .paging button.paging-button[disabled]:hover::before, .viz-legend .paging button.paging-button[disabled]:focus::before, .viz-legend .paging button.paging-button[disabled]:active::before {
          color: #b0beca; }
  .viz-legend.static {
    flex-direction: column;
    margin-left: 20px; }
    .viz-legend.static.position-top, .viz-legend.static.position-bottom {
      align-items: flex-end;
      margin-left: 0; }
      .viz-legend.static.position-top .series, .viz-legend.static.position-bottom .series {
        flex-flow: row wrap;
        box-sizing: border-box;
        justify-content: flex-start;
        width: auto;
        overflow-x: hidden;
        min-width: 0;
        max-width: 100%; }
      .viz-legend.static.position-top .series-item, .viz-legend.static.position-bottom .series-item {
        margin-left: 15px;
        overflow-x: auto; }
    .viz-legend.static.position-right, .viz-legend.static.position-left {
      flex: 0 0 210px; }
      .viz-legend.static.position-right .paging, .viz-legend.static.position-left .paging {
        width: 190px; }
    .viz-legend.static .series {
      flex-direction: column;
      justify-content: flex-start;
      width: 190px;
      min-width: 190px; }
    .viz-legend.static .series-item {
      max-width: 100%; }
    .viz-legend.static .paging {
      display: flex;
      width: 100%;
      margin-top: 20px;
      line-height: 22px; }
      .viz-legend.static .paging .paging-button {
        width: 22px;
        height: 22px;
        min-width: 22px;
        min-height: 22px; }
      .viz-legend.static .paging span {
        display: inline-block;
        margin: 0 10px;
        font-size: 12px;
        color: #94a1ad; }
        .viz-legend.static .paging span strong {
          color: #000; }
  .viz-legend.color-legend {
    font-family: avenir, "Helvetica Neue", arial, sans-serif; }
    .viz-legend.color-legend .labels {
      display: flex;
      font-size: 11px;
      overflow: visible;
      line-height: 20px;
      text-overflow: unset;
      color: #6d7680; }
    .viz-legend.color-legend .boxes {
      display: flex;
      flex: 0 0 auto; }
      .viz-legend.color-legend .boxes .box {
        box-sizing: border-box; }
    .viz-legend.color-legend.position-top, .viz-legend.color-legend.position-bottom {
      flex-direction: column;
      align-items: flex-end; }
      .viz-legend.color-legend.position-top .boxes, .viz-legend.color-legend.position-bottom .boxes {
        height: 10px; }
        .viz-legend.color-legend.position-top .boxes .box, .viz-legend.color-legend.position-bottom .boxes .box {
          width: 50px; }
    .viz-legend.color-legend.position-bottom {
      margin-top: 2px; }
    .viz-legend.color-legend.small .boxes .box {
      width: 40px; }
      .viz-legend.color-legend.small .boxes .box.middle {
        width: 36px; }
    .viz-legend.color-legend.position-left, .viz-legend.color-legend.position-right {
      flex: 0 0 210px;
      align-items: flex-start;
      margin-right: 5px;
      margin-top: 8px; }
      .viz-legend.color-legend.position-left .labels, .viz-legend.color-legend.position-right .labels {
        display: flex;
        flex-direction: column;
        margin-left: 4px;
        text-align: left;
        justify-content: center; }
      .viz-legend.color-legend.position-left .boxes, .viz-legend.color-legend.position-right .boxes {
        flex-direction: column;
        width: 10px;
        margin-left: 20px; }
        .viz-legend.color-legend.position-left .boxes .box, .viz-legend.color-legend.position-right .boxes .box {
          height: 30px; }
  .viz-legend.fluid {
    margin: 0 12px; }
    .viz-legend.fluid .series {
      flex-wrap: wrap; }
      .viz-legend.fluid .series .series-name {
        flex: 1 1 auto; }
    .viz-legend.fluid .paging {
      text-align: right; }
      .viz-legend.fluid .paging .paging-button {
        width: 30px;
        height: 30px; }

.gd-viz-tooltip {
  position: relative;
  border-radius: 3px;
  font-family: "Avenir", "Helvetica Neue", arial, sans-serif;
  color: #000;
  background-color: rgba(255, 255, 255, 0.95); }
  .gd-viz-tooltip .tt-name {
    font-family: "Avenir", "Helvetica Neue", arial, sans-serif;
    font-size: 12px;
    word-wrap: break-word;
    text-transform: uppercase;
    color: #999; }
  .gd-viz-tooltip .tt-tooltip {
    margin-top: 2px;
    margin-bottom: 14px;
    font-size: 14px; }

.gd-viz-tooltip-stroke {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  border-top: 3px solid #848484;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.gd-viz-tooltip-content {
  display: block;
  padding: 10px 9px 9px;
  border-radius: 3px;
  border: 1px solid rgba(201, 213, 224, 0.7);
  box-shadow: 0 1px 3px 0 rgba(20, 56, 93, 0.1), 0 2px 9px 0 rgba(20, 56, 93, 0.15);
  line-height: 1.3;
  white-space: normal; }
  @media only screen and (max-width: 480px) {
    .gd-viz-tooltip-content {
      max-width: none; } }

.gd-viz-tooltip-item {
  display: block;
  margin-bottom: 10px; }
  .gd-viz-tooltip-item:last-child {
    margin-bottom: 0; }

.gd-viz-tooltip-title {
  display: block;
  overflow: hidden;
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  color: #6d7680; }

.gd-viz-tooltip-value-wraper {
  padding-right: 1em; }

.gd-viz-tooltip-value {
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.3em;
  max-height: 2.6em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  word-wrap: break-word; }
  .gd-viz-tooltip-value::before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0; }
  .gd-viz-tooltip-value::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white; }

.gd-viz-tooltip-value-max-content {
  display: block;
  visibility: hidden;
  overflow: hidden;
  font-size: 14px;
  height: 0;
  font-weight: normal;
  white-space: nowrap;
  text-align: left; }

.multiline-supported .gd-viz-tooltip-value-wraper {
  padding-right: 0; }

.multiline-supported .gd-viz-tooltip-value {
  text-align: left;
  margin: 0;
  padding: 0;
  line-height: 1.3em;
  max-height: 2.6em; }
  .multiline-supported .gd-viz-tooltip-value.clamp-two-line {
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2; }
  .multiline-supported .gd-viz-tooltip-value::before {
    content: none; }
  .multiline-supported .gd-viz-tooltip-value::after {
    content: none; }

.highcharts-tooltip-container {
  z-index: 3005; }
  .highcharts-tooltip-container g.highcharts-tooltip {
    visibility: hidden; }

.headline {
  text-align: center; }
  .headline .headline-primary-item {
    color: #000;
    font-family: avenir, "Helvetica Neue", arial, sans-serif;
    font-weight: 400;
    font-weight: 700;
    line-height: 30px;
    font-size: 24px;
    overflow: hidden;
    height: 60px;
    line-height: 60px;
    font-size: 50px;
    white-space: nowrap; }
    .headline .headline-primary-item .headline-value-wrapper {
      display: inline-block;
      -webkit-backface-visibility: hidden; }
    .headline .headline-primary-item.is-drillable .headline-value-wrapper {
      cursor: pointer;
      transition: opacity 0.4s; }
    .headline .headline-primary-item.is-drillable .headline-value-wrapper:hover {
      opacity: 0.6; }
  .headline .headline-secondary-item {
    flex-shrink: 1;
    padding-right: 0;
    border-left: 1px dashed rgba(176, 190, 202, 0.5); }
    .headline .headline-secondary-item.is-drillable {
      cursor: pointer; }
      .headline .headline-secondary-item.is-drillable .headline-value-wrapper,
      .headline .headline-secondary-item.is-drillable .headline-title-wrapper {
        transition: opacity 0.4s; }
      .headline .headline-secondary-item.is-drillable:hover .headline-value-wrapper,
      .headline .headline-secondary-item.is-drillable:hover .headline-title-wrapper {
        opacity: 0.6; }
  .headline .headline-primary-item .headline-value,
  .headline .headline-secondary-item .headline-value {
    position: relative;
    display: inline-block;
    line-height: 115%; }
  .headline .headline-value--empty {
    color: #b0beca; }
  .headline .headline-item-link .headline-value::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block; }
  .headline .headline-item-link .headline-value:not(.headline-value--empty).headline-link-style-underline::after {
    border-bottom: 2px solid; }
  .headline .headline-secondary-item .headline-item-link .headline-value.headline-link-style-underline::after {
    border-bottom: 1px solid; }
  .headline .headline-secondary-item .headline-item-link .headline-value.headline-value--empty::after {
    border-bottom: none; }
  .headline .headline-compare-section {
    position: relative;
    justify-content: center;
    flex-wrap: nowrap;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 15px;
    color: #94a1ad;
    font-family: avenir, "Helvetica Neue", arial, sans-serif;
    font-weight: 400; }
  .headline .headline-compare-section-item {
    overflow: hidden;
    margin: 0;
    padding: 0 15px;
    color: #94a1ad; }
    .headline .headline-compare-section-item .headline-title-wrapper,
    .headline .headline-compare-section-item .headline-value-wrapper {
      line-height: 14px; }
    .headline .headline-compare-section-item .headline-value-wrapper {
      overflow: hidden;
      height: 20px;
      margin-bottom: 0.25em;
      font-size: 17px;
      font-weight: bold;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .headline .headline-compare-section-item .headline-title-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      padding: 0;
      font-size: 12px;
      white-space: nowrap; }

.highcharts-container .highcharts-bullet-series .highcharts-point {
  visibility: hidden; }

.highcharts-container .highcharts-bullet-series .highcharts-bullet-target {
  visibility: visible; }

/* 0, 640px */
/* 641px, 1024px */
/* 1025px, 1440px */
/* 1441px, 1920px */
/* 1921px, ... */
.gd-spinner {
  display: inline-block;
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/images/loading.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  animation: loadingSpinner 1s infinite linear; }
  .gd-spinner.small {
    width: 16px;
    height: 16px; }
  .gd-spinner.large {
    width: 32px;
    height: 32px; }

@keyframes loadingSpinner {
  0 {
    transform: rotate(0deg) translateZ(-0.1px); }
  7.83333% {
    transform: rotate(0deg) translateZ(0.1px); }
  8.33333% {
    transform: rotate(30deg) translateZ(0); }
  16.16667% {
    transform: rotate(30deg) translateZ(0.1px); }
  16.66667% {
    transform: rotate(60deg) translateZ(0); }
  24.5% {
    transform: rotate(60deg) translateZ(0.1px); }
  25% {
    transform: rotate(90deg) translateZ(0); }
  32.83333% {
    transform: rotate(90deg) translateZ(0.1px); }
  33.33333% {
    transform: rotate(120deg) translateZ(0); }
  41.16667% {
    transform: rotate(120deg) translateZ(0.1px); }
  41.66667% {
    transform: rotate(150deg) translateZ(0); }
  49.5% {
    transform: rotate(150deg) translateZ(0.1px); }
  50% {
    transform: rotate(180deg) translateZ(0); }
  57.83333% {
    transform: rotate(180deg) translateZ(0.1px); }
  58.33333% {
    transform: rotate(210deg) translateZ(0); }
  66.16667% {
    transform: rotate(210deg) translateZ(0.1px); }
  66.66667% {
    transform: rotate(240deg) translateZ(0); }
  74.5% {
    transform: rotate(240deg) translateZ(0.1px); }
  75% {
    transform: rotate(270deg) translateZ(0); }
  82.83333% {
    transform: rotate(270deg) translateZ(0.1px); }
  83.33333% {
    transform: rotate(300deg) translateZ(0); }
  91.16667% {
    transform: rotate(300deg) translateZ(0.1px); }
  91.66667% {
    transform: rotate(330deg) translateZ(0); }
  99.5% {
    transform: rotate(330deg) translateZ(0.1px); }
  100% {
    transform: rotate(360deg) translateZ(0); } }

.gd-dot-spinner {
  position: relative;
  width: 34px;
  height: 34px; }
  .gd-dot-spinner-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .gd-dot-spinner div {
    position: absolute;
    top: 50%;
    width: 6px;
    height: 6px;
    background: #94a1ad;
    opacity: 0.3;
    transform: translate(0, -50%) rotate(0);
    border-radius: 50%;
    transform-origin: 17px center; }
  .gd-dot-spinner .gd-dot-spinner-1 {
    transform: translate(0, -50%) rotate(45deg);
    animation: spinner-animation 0.53333s 0.06667s infinite; }
  .gd-dot-spinner .gd-dot-spinner-2 {
    transform: translate(0, -50%) rotate(90deg);
    animation: spinner-animation 0.53333s 0.13333s infinite; }
  .gd-dot-spinner .gd-dot-spinner-3 {
    transform: translate(0, -50%) rotate(135deg);
    animation: spinner-animation 0.53333s 0.2s infinite; }
  .gd-dot-spinner .gd-dot-spinner-4 {
    transform: translate(0, -50%) rotate(180deg);
    animation: spinner-animation 0.53333s 0.26667s infinite; }
  .gd-dot-spinner .gd-dot-spinner-5 {
    transform: translate(0, -50%) rotate(225deg);
    animation: spinner-animation 0.53333s 0.33333s infinite; }
  .gd-dot-spinner .gd-dot-spinner-6 {
    transform: translate(0, -50%) rotate(270deg);
    animation: spinner-animation 0.53333s 0.4s infinite; }
  .gd-dot-spinner .gd-dot-spinner-7 {
    transform: translate(0, -50%) rotate(315deg);
    animation: spinner-animation 0.53333s 0.46667s infinite; }
  .gd-dot-spinner .gd-dot-spinner-8 {
    transform: translate(0, -50%) rotate(360deg);
    animation: spinner-animation 0.53333s 0.53333s infinite; }

@keyframes spinner-animation {
  0% {
    opacity: 0.2; }
  10% {
    opacity: 1; }
  100% {
    opacity: 0.2; } }

.loading-placeholder {
  position: relative;
  display: block;
  height: 24px; }
  .loading-placeholder::before {
    content: "";
    display: inline-block;
    width: 199px;
    height: 14px;
    vertical-align: middle;
    background-image: url("~@gooddata/goodstrap/lib/./core/styles/./images/loading-placeholder.svg");
    background-repeat: no-repeat;
    background-position: 0 0; }
  .loading-placeholder::after {
    content: "";
    position: absolute;
    top: 0;
    right: -100px;
    bottom: 0;
    display: block;
    width: 100px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    animation-name: loading-anim;
    animation-duration: 1s;
    animation-iteration-count: infinite; }

.loading-placeholder-2::before {
  width: 240px;
  background-position: 0 -28px; }

.loading-placeholder-3::before {
  width: 189px;
  background-position: 0 -56px; }

@keyframes loading-anim {
  from {
    right: -100px; }
  to {
    right: calc(100% + 100px); } }

.gd-attribute-filter {
  display: inline-block; }
  .gd-attribute-filter-overlay {
    padding: 16px 8px 8px; }
    .gd-attribute-filter-overlay .gd-message {
      position: relative;
      box-sizing: border-box;
      display: inline-block;
      max-width: 100%;
      padding: 4px 8px 4px 10px;
      min-height: 1px;
      border-radius: 3px;
      font-family: avenir, "Helvetica Neue", arial, sans-serif;
      font-size: 14px;
      text-align: left;
      color: #e54d42;
      background: rgba(229, 77, 66, 0.15);
      pointer-events: all; }
  .gd-attribute-filter-actions {
    text-align: right;
    margin-top: 8px; }

/* ==========================================================================
   Variables, mixins, silent classes etc.
   ========================================================================== */
/* ==========================================================================
   General styles for inputs & selects
   ========================================================================== */
.gd-input {
  position: relative;
  display: block; }

.gd-input-wrapper,
.gd-input-field,
.input-text {
  width: 100%;
  max-width: 100%; }

.gd-input-wrapper {
  position: relative; }

.gd-input-field,
.input-text {
  box-sizing: border-box;
  margin: 0;
  padding: 6px 7px 5px;
  border: 1px solid #ccd8e2;
  line-height: normal;
  vertical-align: middle;
  color: #464e56;
  background: #fff;
  font-size: 14px;
  font-family: avenir, "Helvetica Neue", arial, sans-serif;
  font-weight: 200;
  -moz-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px 0 rgba(31, 53, 74, 0.15); }
  .gd-input-field::-webkit-input-placeholder,
  .input-text::-webkit-input-placeholder {
    color: rgba(109, 118, 128, 0.75);
    -moz-transition-property: color;
    -webkit-transition-property: color;
    transition-property: color;
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out; }
  .gd-input-field:-moz-placeholder,
  .input-text:-moz-placeholder {
    color: rgba(109, 118, 128, 0.75);
    -moz-transition-property: color;
    -webkit-transition-property: color;
    transition-property: color;
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out; }
  .gd-input-field::-moz-placeholder,
  .input-text::-moz-placeholder {
    color: rgba(109, 118, 128, 0.75);
    -moz-transition-property: color;
    -webkit-transition-property: color;
    transition-property: color;
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out; }
  .gd-input-field:-ms-input-placeholder,
  .input-text:-ms-input-placeholder {
    color: rgba(109, 118, 128, 0.75);
    -moz-transition-property: color;
    -webkit-transition-property: color;
    transition-property: color;
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out; }
  .gd-input-field:hover,
  .input-text:hover {
    border-color: #b1c1d1; }
    .gd-input-field:hover::-webkit-input-placeholder,
    .input-text:hover::-webkit-input-placeholder {
      color: #6d7680; }
    .gd-input-field:hover:-moz-placeholder,
    .input-text:hover:-moz-placeholder {
      color: #6d7680; }
    .gd-input-field:hover::-moz-placeholder,
    .input-text:hover::-moz-placeholder {
      color: #6d7680; }
    .gd-input-field:hover:-ms-input-placeholder,
    .input-text:hover:-ms-input-placeholder {
      color: #6d7680; }
  .gd-input-field:focus,
  .input-text:focus {
    outline: 0;
    border-color: #14b2e2;
    box-shadow: inset 0 1px 1px 0 rgba(31, 53, 74, 0.2); }
  .gd-input-field:disabled, .gd-input-field:disabled:hover, .gd-input-field.disabled, .gd-input-field.disabled:hover,
  .input-text:disabled,
  .input-text:disabled:hover,
  .input-text.disabled,
  .input-text.disabled:hover {
    color: #b0beca;
    border-color: #ccd8e2;
    background-color: #fcfcfd;
    box-shadow: none; }
  .gd-input-field.gd-input-field-small, .gd-input-field.small,
  .input-text.gd-input-field-small,
  .input-text.small {
    height: 27.1px;
    padding: 5px 7px;
    font-size: 13px; }

textarea.gd-input, textarea.input-text {
  height: auto; }

/* Searchfield
   ========================================================================== */
.gd-input-search,
.searchfield {
  position: relative; }
  .gd-input-search.gd-input-small .gd-input-field,
  .gd-input-search.gd-input-small .searchfield-input, .gd-input-search.small .gd-input-field,
  .gd-input-search.small .searchfield-input,
  .searchfield.gd-input-small .gd-input-field,
  .searchfield.gd-input-small .searchfield-input,
  .searchfield.small .gd-input-field,
  .searchfield.small .searchfield-input {
    padding-left: 30px;
    padding-right: 30px; }
  .gd-input-search.gd-input-small .gd-input-icon,
  .gd-input-search.gd-input-small .searchfield-icon, .gd-input-search.small .gd-input-icon,
  .gd-input-search.small .searchfield-icon,
  .searchfield.gd-input-small .gd-input-icon,
  .searchfield.gd-input-small .searchfield-icon,
  .searchfield.small .gd-input-icon,
  .searchfield.small .searchfield-icon {
    left: 8px; }
  .gd-input-search.gd-input-small .gd-input-icon-clear,
  .gd-input-search.gd-input-small .searchfield-clear, .gd-input-search.small .gd-input-icon-clear,
  .gd-input-search.small .searchfield-clear,
  .searchfield.gd-input-small .gd-input-icon-clear,
  .searchfield.gd-input-small .searchfield-clear,
  .searchfield.small .gd-input-icon-clear,
  .searchfield.small .searchfield-clear {
    right: 8px; }
  .gd-input-search.gd-input-small .gd-input-icon,
  .gd-input-search.gd-input-small .gd-input-icon-clear,
  .gd-input-search.gd-input-small .searchfield-icon,
  .gd-input-search.gd-input-small .searchfield-clear, .gd-input-search.small .gd-input-icon,
  .gd-input-search.small .gd-input-icon-clear,
  .gd-input-search.small .searchfield-icon,
  .gd-input-search.small .searchfield-clear,
  .searchfield.gd-input-small .gd-input-icon,
  .searchfield.gd-input-small .gd-input-icon-clear,
  .searchfield.gd-input-small .searchfield-icon,
  .searchfield.gd-input-small .searchfield-clear,
  .searchfield.small .gd-input-icon,
  .searchfield.small .gd-input-icon-clear,
  .searchfield.small .searchfield-icon,
  .searchfield.small .searchfield-clear {
    line-height: 29px;
    font-size: 14px; }

.gd-input-search .gd-input-field,
.searchfield-input {
  padding-left: 36px;
  padding-right: 36px; }

.gd-input-icon,
.searchfield-icon {
  left: 10px;
  color: #b0beca;
  position: absolute;
  top: 0;
  bottom: 0;
  line-height: 32px;
  font-size: 16px;
  -moz-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out; }
  :focus + .gd-input-icon, :focus +
  .searchfield-icon {
    color: #14b2e2; }

.gd-input-icon-clear,
.searchfield-clear {
  right: 10px;
  color: #6d7680;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  line-height: 32px;
  font-size: 16px;
  -moz-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out; }
  .gd-input-icon-clear:hover,
  .searchfield-clear:hover {
    color: #14b2e2; }

/* Input states
   ========================================================================== */
.has-error .gd-input-field {
  color: #464e56;
  border-color: rgba(229, 77, 66, 0.5);
  box-shadow: inset 0 0 2px 0 rgba(170, 33, 23, 0.3); }

.has-error .gd-input-icon {
  color: #e54d42; }

.has-warning .gd-input-field {
  border-color: #fada23;
  box-shadow: inset 0 0 2px 0 rgba(179, 153, 4, 0.3); }

/* Input label
   ========================================================================== */
.gd-input-with-label {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .gd-input-with-label .gd-input-wrapper {
    flex: 1 1 auto; }
  .gd-input-with-label.is-disabled .gd-input-label {
    color: #b0beca;
    user-select: none; }
  .gd-input-with-label.has-error .gd-input-label {
    color: #e54d42; }

.gd-input-label {
  flex: 0 0 auto;
  margin-right: 20px;
  white-space: nowrap;
  color: #6d7680;
  font-size: 14px; }

.gd-input-label-top {
  flex-wrap: wrap; }
  .gd-input-label-top .gd-input-label {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
    font-size: 10px;
    text-transform: uppercase; }

/* Input prefixes and suffixes
   ========================================================================== */
.gd-input-with-prefix .gd-input-wrapper,
.gd-input-with-suffix .gd-input-wrapper {
  display: flex; }

.gd-input-with-prefix:hover .gd-input-field,
.gd-input-with-prefix:hover .gd-input-prefix,
.gd-input-with-prefix:hover .gd-input-suffix,
.gd-input-with-suffix:hover .gd-input-field,
.gd-input-with-suffix:hover .gd-input-prefix,
.gd-input-with-suffix:hover .gd-input-suffix {
  border-color: #b1c1d1; }

.gd-input-with-prefix:hover .gd-input-field:disabled,
.gd-input-with-prefix:hover .gd-input-field:disabled ~ .gd-input-prefix,
.gd-input-with-prefix:hover .gd-input-field:disabled ~ .gd-input-suffix,
.gd-input-with-suffix:hover .gd-input-field:disabled,
.gd-input-with-suffix:hover .gd-input-field:disabled ~ .gd-input-prefix,
.gd-input-with-suffix:hover .gd-input-field:disabled ~ .gd-input-suffix {
  border-color: #ccd8e2; }

.gd-input-with-prefix .gd-input-prefix,
.gd-input-with-suffix .gd-input-prefix {
  order: 0; }

.gd-input-with-prefix .gd-input-field,
.gd-input-with-suffix .gd-input-field {
  flex: 1 1 100%;
  min-width: 0; }
  .gd-input-with-prefix .gd-input-field:focus,
  .gd-input-with-prefix .gd-input-field:focus ~ .gd-input-prefix,
  .gd-input-with-prefix .gd-input-field:focus ~ .gd-input-suffix,
  .gd-input-with-suffix .gd-input-field:focus,
  .gd-input-with-suffix .gd-input-field:focus ~ .gd-input-prefix,
  .gd-input-with-suffix .gd-input-field:focus ~ .gd-input-suffix {
    border-color: #14b2e2; }
  .gd-input-with-prefix .gd-input-field.gd-input-field-small,
  .gd-input-with-prefix .gd-input-field.gd-input-field-small ~ .gd-input-prefix,
  .gd-input-with-prefix .gd-input-field.gd-input-field-small ~ .gd-input-suffix, .gd-input-with-prefix .gd-input-field.small,
  .gd-input-with-prefix .gd-input-field.small ~ .gd-input-prefix,
  .gd-input-with-prefix .gd-input-field.small ~ .gd-input-suffix,
  .gd-input-with-suffix .gd-input-field.gd-input-field-small,
  .gd-input-with-suffix .gd-input-field.gd-input-field-small ~ .gd-input-prefix,
  .gd-input-with-suffix .gd-input-field.gd-input-field-small ~ .gd-input-suffix,
  .gd-input-with-suffix .gd-input-field.small,
  .gd-input-with-suffix .gd-input-field.small ~ .gd-input-prefix,
  .gd-input-with-suffix .gd-input-field.small ~ .gd-input-suffix {
    height: 27.1px;
    padding: 5px 7px;
    font-size: 13px; }
  .gd-input-with-prefix .gd-input-field.gd-input-field-small ~ .gd-input-prefix,
  .gd-input-with-prefix .gd-input-field.gd-input-field-small ~ .gd-input-suffix, .gd-input-with-prefix .gd-input-field.small ~ .gd-input-prefix,
  .gd-input-with-prefix .gd-input-field.small ~ .gd-input-suffix,
  .gd-input-with-suffix .gd-input-field.gd-input-field-small ~ .gd-input-prefix,
  .gd-input-with-suffix .gd-input-field.gd-input-field-small ~ .gd-input-suffix,
  .gd-input-with-suffix .gd-input-field.small ~ .gd-input-prefix,
  .gd-input-with-suffix .gd-input-field.small ~ .gd-input-suffix {
    min-width: 27px; }

.gd-input-with-prefix .gd-input-field,
.gd-input-with-prefix .gd-input-suffix,
.gd-input-with-suffix .gd-input-field,
.gd-input-with-suffix .gd-input-suffix {
  order: 1; }

.gd-input-with-prefix.has-error .gd-input-field,
.gd-input-with-prefix.has-error .gd-input-field ~ .gd-input-prefix,
.gd-input-with-prefix.has-error .gd-input-field ~ .gd-input-suffix,
.gd-input-with-suffix.has-error .gd-input-field,
.gd-input-with-suffix.has-error .gd-input-field ~ .gd-input-prefix,
.gd-input-with-suffix.has-error .gd-input-field ~ .gd-input-suffix {
  border-color: rgba(229, 77, 66, 0.5); }

.gd-input-with-prefix .gd-input-field {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.gd-input-with-suffix .gd-input-field {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.gd-input-prefix,
.gd-input-suffix {
  display: block;
  flex: 0 0 auto;
  box-sizing: border-box;
  margin: 0;
  padding: 6px 7px 5px;
  border: 1px solid #ccd8e2;
  line-height: normal;
  vertical-align: middle;
  color: #464e56;
  background: #fff;
  font-size: 14px;
  font-family: avenir, "Helvetica Neue", arial, sans-serif;
  font-weight: 200;
  -moz-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  color: #b0beca;
  min-width: 30px;
  text-align: center;
  background-color: #fcfcfd; }

.gd-input-prefix {
  border-right: none;
  border-radius: 3px 0 0 3px; }

.gd-input-suffix {
  border-left: none;
  border-radius: 0 3px 3px 0; }

/* Checkbox and radio
   ========================================================================== */
.input-radio-label,
.input-checkbox-label {
  position: relative;
  display: inline-block;
  height: 20px;
  padding: 0 10px 0 23px;
  line-height: 20px;
  margin-left: -3px; }
  .input-radio-label .input-label-text,
  .input-checkbox-label .input-label-text {
    line-height: inherit;
    vertical-align: middle;
    color: #464e56; }
    .input-radio-label .input-label-text::before, .input-radio-label .input-label-text::after,
    .input-checkbox-label .input-label-text::before,
    .input-checkbox-label .input-label-text::after {
      content: "";
      position: absolute;
      box-sizing: content-box;
      cursor: pointer; }
    .input-radio-label .input-label-text::before,
    .input-checkbox-label .input-label-text::before {
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      border: 1px solid #b1c1d1;
      transition: all 0.2s;
      background-color: #fff; }
    .input-radio-label .input-label-text::after,
    .input-checkbox-label .input-label-text::after {
      transform: scale(0);
      transition: transform 0.2s; }
  .input-radio-label .gd-checkbox-label-small,
  .input-checkbox-label .gd-checkbox-label-small {
    line-height: inherit;
    vertical-align: inherit; }
  .input-radio-label input:disabled ~ .input-label-text::before,
  .input-radio-label input.disabled ~ .input-label-text::before, .input-radio-label:hover input:disabled ~ .input-label-text::before,
  .input-radio-label:hover input.disabled ~ .input-label-text::before, .input-radio-label:active input:disabled ~ .input-label-text::before,
  .input-radio-label:active input.disabled ~ .input-label-text::before,
  .input-checkbox-label input:disabled ~ .input-label-text::before,
  .input-checkbox-label input.disabled ~ .input-label-text::before,
  .input-checkbox-label:hover input:disabled ~ .input-label-text::before,
  .input-checkbox-label:hover input.disabled ~ .input-label-text::before,
  .input-checkbox-label:active input:disabled ~ .input-label-text::before,
  .input-checkbox-label:active input.disabled ~ .input-label-text::before {
    box-shadow: none;
    border-color: rgba(204, 216, 226, 0.7);
    background-color: #fcfcfd; }
  .input-radio-label:hover .input-label-text::before, .input-radio-label:active .input-label-text::before,
  .input-checkbox-label:hover .input-label-text::before,
  .input-checkbox-label:active .input-label-text::before {
    box-shadow: inset 0 1px 2px 0 rgba(31, 53, 74, 0.25); }
  .input-radio-label:hover input:checked ~ .input-label-text::before, .input-radio-label:active input:checked ~ .input-label-text::before,
  .input-checkbox-label:hover input:checked ~ .input-label-text::before,
  .input-checkbox-label:active input:checked ~ .input-label-text::before {
    box-shadow: none;
    border-color: transparent; }
  .input-radio-label:hover .input-label-text::before,
  .input-checkbox-label:hover .input-label-text::before {
    border-color: #94a1ad; }
  .input-radio-label:active .input-label-text::before,
  .input-checkbox-label:active .input-label-text::before {
    border-color: #14b2e2;
    background-color: #e8f7fc; }
  .input-radio-label input,
  .input-checkbox-label input {
    position: absolute;
    opacity: 0; }
    .input-radio-label input:checked ~ .input-label-text::before,
    .input-checkbox-label input:checked ~ .input-label-text::before {
      border-color: transparent;
      background-color: #14b2e2; }
    .input-radio-label input:checked ~ .input-label-text::after,
    .input-checkbox-label input:checked ~ .input-label-text::after {
      transform: scale(1); }
    .input-radio-label input:disabled ~ .input-label-text, .input-radio-label input.disabled ~ .input-label-text,
    .input-checkbox-label input:disabled ~ .input-label-text,
    .input-checkbox-label input.disabled ~ .input-label-text {
      color: #b0beca; }
    .input-radio-label input:disabled ~ .input-label-text::before,
    .input-radio-label input:disabled ~ .input-label-text::after, .input-radio-label input.disabled ~ .input-label-text::before,
    .input-radio-label input.disabled ~ .input-label-text::after,
    .input-checkbox-label input:disabled ~ .input-label-text::before,
    .input-checkbox-label input:disabled ~ .input-label-text::after,
    .input-checkbox-label input.disabled ~ .input-label-text::before,
    .input-checkbox-label input.disabled ~ .input-label-text::after {
      cursor: default; }
    .input-radio-label input:disabled:checked ~ .input-label-text::before, .input-radio-label input.disabled:checked ~ .input-label-text::before,
    .input-checkbox-label input:disabled:checked ~ .input-label-text::before,
    .input-checkbox-label input.disabled:checked ~ .input-label-text::before {
      background-color: #ccd8e2; }

.input-radio-label .input-label-text::before {
  border-radius: 50%; }

.input-radio-label .input-label-text::after {
  top: 7px;
  left: 7px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff; }

.input-checkbox-label .input-label-text::before {
  border-radius: 2px; }

.input-checkbox-label .input-label-text::after {
  top: 7px;
  left: 4px;
  width: 7px;
  height: 3px;
  transform: scale(0) rotate(315deg) skew(-2deg);
  transform-origin: top;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff; }

.input-checkbox-label input:checked ~ .input-label-text::after {
  transform: scale(1) rotate(315deg) skew(-2deg); }

.input-checkbox-label .checkbox-indefinite ~ .input-label-text::after {
  top: 9px;
  left: 6px;
  width: 8px;
  height: 0;
  transform: scale(0);
  border-left: none; }

.input-checkbox-label .checkbox-indefinite:checked ~ .input-label-text::after {
  transform: scale(1); }

.input-checkbox-toggle input {
  position: absolute;
  opacity: 0; }
  .input-checkbox-toggle input:disabled ~ .input-label-text, .input-checkbox-toggle input.disabled ~ .input-label-text {
    color: #b0beca; }

.input-checkbox-toggle .input-label-text {
  position: relative;
  height: 14px;
  line-height: 14px;
  padding-right: 35px; }
  .input-checkbox-toggle .input-label-text::before, .input-checkbox-toggle .input-label-text::after {
    content: "";
    position: absolute;
    box-sizing: content-box;
    background: #f3f6f7;
    cursor: pointer;
    transition: all 0.4s; }
  .input-checkbox-toggle .input-label-text::before {
    top: 1px;
    right: 0;
    width: 28px;
    height: 12px;
    border: 1px solid #c9d5e0;
    border-radius: 7px; }
  .input-checkbox-toggle .input-label-text::after {
    top: 3px;
    right: 18px;
    width: 8px;
    height: 8px;
    border: 1px solid #b0beca;
    border-radius: 50%; }

.input-checkbox-toggle input:checked ~ .input-label-text::after {
  right: 2px;
  border: 1px solid #129cc6;
  background: #14b2e2; }

.input-checkbox-toggle:hover .input-label-text::after, .input-checkbox-toggle:active .input-label-text::after {
  border: 1px solid #94a1ad;
  background: #d7dfe5; }

.input-checkbox-toggle:hover input:checked ~ .input-label-text::after, .input-checkbox-toggle:active input:checked ~ .input-label-text::after {
  background: #129cc6; }

.input-checkbox-toggle input:disabled ~ .input-label-text::before,
.input-checkbox-toggle input:disabled ~ .input-label-text::after,
.input-checkbox-toggle input.disabled ~ .input-label-text::before,
.input-checkbox-toggle input.disabled ~ .input-label-text::after, .input-checkbox-toggle:hover input:disabled ~ .input-label-text::before,
.input-checkbox-toggle:hover input:disabled ~ .input-label-text::after,
.input-checkbox-toggle:hover input.disabled ~ .input-label-text::before,
.input-checkbox-toggle:hover input.disabled ~ .input-label-text::after, .input-checkbox-toggle:active input:disabled ~ .input-label-text::before,
.input-checkbox-toggle:active input:disabled ~ .input-label-text::after,
.input-checkbox-toggle:active input.disabled ~ .input-label-text::before,
.input-checkbox-toggle:active input.disabled ~ .input-label-text::after {
  box-shadow: none;
  cursor: default;
  border-color: rgba(204, 216, 226, 0.5);
  background-color: #fcfcfd; }

.icon-fact::before,
.icon-measure::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-fact[class*="gd-button-"]::before,
.icon-measure[class*="gd-button-"]::before {
  height: 100%; }

.icon-fact::before,
.icon-measure::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/measure.svg"); }

.icon-fact.disabled::before, .icon-fact[disabled="disabled"]::before,
.icon-measure.disabled::before,
.icon-measure[disabled="disabled"]::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-fact.disabled[class*="gd-button-"]::before, .icon-fact[disabled="disabled"][class*="gd-button-"]::before,
.icon-measure.disabled[class*="gd-button-"]::before,
.icon-measure[disabled="disabled"][class*="gd-button-"]::before {
  height: 100%; }

.icon-fact.disabled::before, .icon-fact[disabled="disabled"]::before,
.icon-measure.disabled::before,
.icon-measure[disabled="disabled"]::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/measure-disabled.svg"); }

.icon-fact.gd-button-small::before, .icon-fact.gd-button-link::before, .icon-fact.gd-button-link-dimmed::before,
.icon-measure.gd-button-small::before,
.icon-measure.gd-button-link::before,
.icon-measure.gd-button-link-dimmed::before {
  margin-top: -1px; }

.icon-attribute::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-attribute[class*="gd-button-"]::before {
  height: 100%; }

.icon-attribute::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/attribute.svg"); }

.icon-attribute.disabled::before, .icon-attribute[disabled="disabled"]::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-attribute.disabled[class*="gd-button-"]::before, .icon-attribute[disabled="disabled"][class*="gd-button-"]::before {
  height: 100%; }

.icon-attribute.disabled::before, .icon-attribute[disabled="disabled"]::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/attribute-disabled.svg"); }

.icon-attribute.gd-button-small::before, .icon-attribute.gd-button-link::before, .icon-attribute.gd-button-link-dimmed::before {
  margin-top: -1px; }

.icon-date::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-date[class*="gd-button-"]::before {
  height: 100%; }

.icon-date::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/date.svg"); }

.icon-date.disabled::before, .icon-date[disabled="disabled"]::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-date.disabled[class*="gd-button-"]::before, .icon-date[disabled="disabled"][class*="gd-button-"]::before {
  height: 100%; }

.icon-date.disabled::before, .icon-date[disabled="disabled"]::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/date-disabled.svg"); }

.icon-date.gd-button-small::before {
  margin-top: -1px; }

.icon-date.gd-button-link::before, .icon-date.gd-button-link-dimmed::before {
  margin-top: -2px; }

.icon-measure-value-filter::before, .icon-all::before, .icon-greater-than::before, .icon-greater-than-equal-to::before, .icon-less-than::before, .icon-less-than-equal-to::before, .icon-between::before, .icon-not-between::before, .icon-equal-to::before, .icon-not-equal-to::before {
  width: 18px;
  height: 15px; }

.icon-all::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-all[class*="gd-button-"]::before {
  height: 100%; }

.icon-all::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/all.svg"); }

.icon-all.gd-button-small::before {
  margin-top: -1px; }

.icon-all.gd-button-link::before, .icon-all.gd-button-link-dimmed::before {
  margin-top: -2px; }

.icon-greater-than::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-greater-than[class*="gd-button-"]::before {
  height: 100%; }

.icon-greater-than::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/greater-than.svg"); }

.icon-greater-than.gd-button-small::before {
  margin-top: -1px; }

.icon-greater-than.gd-button-link::before, .icon-greater-than.gd-button-link-dimmed::before {
  margin-top: -2px; }

.icon-greater-than-equal-to::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-greater-than-equal-to[class*="gd-button-"]::before {
  height: 100%; }

.icon-greater-than-equal-to::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/greater-than-equal-to.svg"); }

.icon-greater-than-equal-to.gd-button-small::before {
  margin-top: -1px; }

.icon-greater-than-equal-to.gd-button-link::before, .icon-greater-than-equal-to.gd-button-link-dimmed::before {
  margin-top: -2px; }

.icon-less-than::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-less-than[class*="gd-button-"]::before {
  height: 100%; }

.icon-less-than::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/less-than.svg"); }

.icon-less-than.gd-button-small::before {
  margin-top: -1px; }

.icon-less-than.gd-button-link::before, .icon-less-than.gd-button-link-dimmed::before {
  margin-top: -2px; }

.icon-less-than-equal-to::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-less-than-equal-to[class*="gd-button-"]::before {
  height: 100%; }

.icon-less-than-equal-to::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/less-than-equal-to.svg"); }

.icon-less-than-equal-to.gd-button-small::before {
  margin-top: -1px; }

.icon-less-than-equal-to.gd-button-link::before, .icon-less-than-equal-to.gd-button-link-dimmed::before {
  margin-top: -2px; }

.icon-between::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-between[class*="gd-button-"]::before {
  height: 100%; }

.icon-between::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/between.svg"); }

.icon-between.gd-button-small::before {
  margin-top: -1px; }

.icon-between.gd-button-link::before, .icon-between.gd-button-link-dimmed::before {
  margin-top: -2px; }

.icon-not-between::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-not-between[class*="gd-button-"]::before {
  height: 100%; }

.icon-not-between::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/not-between.svg"); }

.icon-not-between.gd-button-small::before {
  margin-top: -1px; }

.icon-not-between.gd-button-link::before, .icon-not-between.gd-button-link-dimmed::before {
  margin-top: -2px; }

.icon-equal-to::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-equal-to[class*="gd-button-"]::before {
  height: 100%; }

.icon-equal-to::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/equal-to.svg"); }

.icon-equal-to.gd-button-small::before {
  margin-top: -1px; }

.icon-equal-to.gd-button-link::before, .icon-equal-to.gd-button-link-dimmed::before {
  margin-top: -2px; }

.icon-not-equal-to::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-not-equal-to[class*="gd-button-"]::before {
  height: 100%; }

.icon-not-equal-to::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/not-equal-to.svg"); }

.icon-not-equal-to.gd-button-small::before {
  margin-top: -1px; }

.icon-not-equal-to.gd-button-link::before, .icon-not-equal-to.gd-button-link-dimmed::before {
  margin-top: -2px; }

.icon-label::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-label[class*="gd-button-"]::before {
  height: 100%; }

.icon-label::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/label.svg"); }

.icon-label-disabled::before, .icon-label.disabled::before, .icon-label[disabled="disabled"]::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-label-disabled[class*="gd-button-"]::before, .icon-label.disabled[class*="gd-button-"]::before, .icon-label[disabled="disabled"][class*="gd-button-"]::before {
  height: 100%; }

.icon-label-disabled::before, .icon-label.disabled::before, .icon-label[disabled="disabled"]::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/label-disabled.svg"); }

.icon-label-active::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-label-active[class*="gd-button-"]::before {
  height: 100%; }

.icon-label-active::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/label-active.svg"); }

.icon-label-warning::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-label-warning[class*="gd-button-"]::before {
  height: 100%; }

.icon-label-warning::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/label-warning.svg"); }

.icon-hyperlink::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-hyperlink[class*="gd-button-"]::before {
  height: 100%; }

.icon-hyperlink::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/hyperlink.svg"); }

.icon-hyperlink-disabled::before, .icon-hyperlink.disabled::before, .icon-hyperlink[disabled="disabled"]::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-hyperlink-disabled[class*="gd-button-"]::before, .icon-hyperlink.disabled[class*="gd-button-"]::before, .icon-hyperlink[disabled="disabled"][class*="gd-button-"]::before {
  height: 100%; }

.icon-hyperlink-disabled::before, .icon-hyperlink.disabled::before, .icon-hyperlink[disabled="disabled"]::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/hyperlink-disabled.svg"); }

.icon-hyperlink-active::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-hyperlink-active[class*="gd-button-"]::before {
  height: 100%; }

.icon-hyperlink-active::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/hyperlink-active.svg"); }

.icon-hyperlink-warning::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center; }

.icon-hyperlink-warning[class*="gd-button-"]::before {
  height: 100%; }

.icon-hyperlink-warning::before {
  background-image: url("~@gooddata/goodstrap/lib/./core/styles/icons/hyperlink-warning.svg"); }

.gd-mvf-dropdown-body {
  width: 250px; }
  .gd-mvf-dropdown-body .gd-mvf-dropdown-section {
    padding: 10px 10px 0; }
    .gd-mvf-dropdown-body .gd-mvf-dropdown-section .gd-mvf-warning-message {
      font-size: 12px;
      padding: 4px 8px 4px 10px;
      line-height: 1.4em;
      color: #f18600;
      background: rgba(250, 218, 35, 0.15);
      border-radius: 3px;
      font-family: avenir, "Helvetica Neue", arial, sans-serif;
      margin-top: 4px; }
    .gd-mvf-dropdown-body .gd-mvf-dropdown-section .gd-mvf-treat-null-values-as-zero {
      margin-top: 8px;
      font-size: 12px; }
      .gd-mvf-dropdown-body .gd-mvf-dropdown-section .gd-mvf-treat-null-values-as-zero .inlineBubbleHelp {
        margin: 0 0 2px 5px;
        font-size: 12px; }
    .gd-mvf-dropdown-body .gd-mvf-dropdown-section:last-child {
      padding-bottom: 10px; }
  .gd-mvf-dropdown-body .gd-mvf-dropdown-footer {
    margin: 0 10px;
    padding: 10px 0;
    border-top: 1px solid #dde4eb;
    text-align: right; }

.gd-mvf-operator-dropdown-button {
  width: 100%; }

.gd-mvf-operator-dropdown-body {
  width: 230px;
  padding: 5px 0; }
  .gd-mvf-operator-dropdown-body .gd-list-item {
    padding: 5px 10px; }
    .gd-mvf-operator-dropdown-body .gd-list-item > span {
      margin-left: 5px; }
    .gd-mvf-operator-dropdown-body .gd-list-item > .tooltip-bubble {
      margin-right: 5px;
      text-align: right; }

.gd-mvf-range-input {
  display: flex; }
  .gd-mvf-range-input.hidden {
    display: none; }
  .gd-mvf-range-input .gd-input {
    flex: auto; }
    .gd-mvf-range-input .gd-input:last-child {
      padding-left: 10px; }

.gd-mvf-compare-input.hidden {
  display: none; }

.gd-measure-number-format-dropdown-body {
  padding: 5px 0; }
  .gd-measure-number-format-dropdown-body .gd-format-preset {
    width: 200px;
    padding: 2.5px 10px; }
    .gd-measure-number-format-dropdown-body .gd-format-preset > .gd-format-preset-name {
      margin-left: 5px; }
    .gd-measure-number-format-dropdown-body .gd-format-preset > .gd-format-preset-preview {
      color: #b0beca;
      font-weight: normal;
      margin-right: 5px;
      text-align: right; }

.gd-measure-custom-format-dialog-body {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: inherit;
  font-size: 12px; }
  .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-header {
    flex: 0 0 auto;
    padding: 10px 12px;
    background-color: #ebeff4;
    border-bottom: 1px solid #dde4eb; }
    .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-header > span {
      color: #94a1ad;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold; }
  .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content {
    flex: 0 0 auto;
    padding: 4px 12px 10px; }
    .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-custom-format-dialog-section {
      margin-top: 4px; }
    .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-custom-format-dialog-section-title {
      display: flex;
      justify-content: space-between; }
    .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-custom-format-dialog-section-help > a {
      text-decoration: initial; }
    .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-custom-format-dialog-section-preview {
      margin-top: 5px; }
    .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-custom-format-dialog-preview {
      display: flex;
      flex-flow: row;
      line-height: 28px; }
      .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-custom-format-dialog-preview .gd-measure-custom-format-dialog-preview-input {
        flex: 0 0 42%; }
      .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-custom-format-dialog-preview .gd-measure-custom-format-dialog-preview-string {
        box-sizing: border-box;
        flex: 0 0 58%;
        padding-left: 8px;
        font-weight: bold; }
    .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-format-button {
      display: flex;
      outline: none;
      color: #94a1ad;
      cursor: pointer;
      user-select: none; }
      .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-format-button:hover {
        color: #464e56; }
      .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-format-button:hover > span {
        text-decoration: underline; }
      .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-format-button .gd-measure-format-button-icon-left {
        margin-right: 4px; }
      .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-format-button .gd-measure-format-button-icon-right {
        margin-left: 4px; }
      .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-format-button.hidden {
        display: none; }
    .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-format-button-templates {
      color: #14b2e2; }
      .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-format-button-templates:hover {
        color: #0c6884; }
    .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-format-template-preview {
      position: relative; }
      .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content .gd-measure-format-template-preview .gd-measure-format-template-preview-bubble {
        position: absolute; }
    .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-content textarea {
      height: 100px;
      resize: none; }
  .gd-measure-custom-format-dialog-body .gd-measure-format-extended-preview {
    margin-top: 5px; }
  .gd-measure-custom-format-dialog-body .gd-measure-custom-format-dialog-footer {
    flex: 0 0 auto;
    margin: 0 10px;
    padding: 10px 0;
    border-top: 1px solid #dde4eb;
    text-align: right; }

.gd-measure-format-extended-preview-row {
  display: flex;
  line-height: 20px; }

.gd-measure-format-extended-preview-number {
  flex: 0 0 42%; }

.gd-measure-format-extended-preview-formatted {
  box-sizing: border-box;
  flex: 0 0 58%;
  padding-left: 8px;
  font-weight: bold; }

.gd-measure-format-template-preview-bubble {
  width: 210px; }
  .gd-measure-format-template-preview-bubble .content {
    padding: 5px; }
    .gd-measure-format-template-preview-bubble .content .gd-measure-format-template-preview-bubble-title {
      margin: 0 0 10px;
      font-size: 14px;
      color: #464e56;
      font-weight: normal; }
    .gd-measure-format-template-preview-bubble .content .gd-measure-format-template-preview-bubble-subtitle {
      margin: 0 0 5px;
      font-size: 10px;
      font-weight: normal;
      text-transform: uppercase;
      color: #94a1ad; }

.gd-format-template .gd-format-template-help {
  display: none;
  line-height: 10px;
  color: #6d7680; }

.gd-format-template:hover .gd-format-template-help {
  display: block; }

.gd-measure-format-preview-formatted {
  overflow: hidden;
  text-overflow: ellipsis; }
  .gd-measure-format-preview-formatted > span {
    white-space: nowrap; }

.gd-input-syntax-highlighting-input .CodeMirror {
  box-sizing: border-box;
  margin: 0;
  padding: 6px 7px 5px;
  border: 1px solid #ccd8e2;
  line-height: normal;
  vertical-align: middle;
  color: #464e56;
  background: #fff;
  font-size: 14px;
  font-family: avenir, "Helvetica Neue", arial, sans-serif;
  font-weight: 200;
  -moz-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px 0 rgba(31, 53, 74, 0.15);
  font-family: monospace;
  height: 100px;
  padding: 2px 2px 0 3px; }
  .gd-input-syntax-highlighting-input .CodeMirror::-webkit-input-placeholder {
    color: rgba(109, 118, 128, 0.75);
    -moz-transition-property: color;
    -webkit-transition-property: color;
    transition-property: color;
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out; }
  .gd-input-syntax-highlighting-input .CodeMirror:-moz-placeholder {
    color: rgba(109, 118, 128, 0.75);
    -moz-transition-property: color;
    -webkit-transition-property: color;
    transition-property: color;
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out; }
  .gd-input-syntax-highlighting-input .CodeMirror::-moz-placeholder {
    color: rgba(109, 118, 128, 0.75);
    -moz-transition-property: color;
    -webkit-transition-property: color;
    transition-property: color;
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out; }
  .gd-input-syntax-highlighting-input .CodeMirror:-ms-input-placeholder {
    color: rgba(109, 118, 128, 0.75);
    -moz-transition-property: color;
    -webkit-transition-property: color;
    transition-property: color;
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out; }
  .gd-input-syntax-highlighting-input .CodeMirror:hover {
    border-color: #b1c1d1; }
    .gd-input-syntax-highlighting-input .CodeMirror:hover::-webkit-input-placeholder {
      color: #6d7680; }
    .gd-input-syntax-highlighting-input .CodeMirror:hover:-moz-placeholder {
      color: #6d7680; }
    .gd-input-syntax-highlighting-input .CodeMirror:hover::-moz-placeholder {
      color: #6d7680; }
    .gd-input-syntax-highlighting-input .CodeMirror:hover:-ms-input-placeholder {
      color: #6d7680; }
  .gd-input-syntax-highlighting-input .CodeMirror.CodeMirror-focused {
    border-color: #14b2e2;
    box-shadow: inset 0 1px 1px 0 rgba(31, 53, 74, 0.15); }

.gd-input-syntax-highlighting-input .CodeMirror-vscrollbar:focus {
  outline: 0; }

.gd-input-syntax-highlighting-input .CodeMirror-empty {
  color: #b0beca; }

.gd-input-syntax-highlighting-input .CodeMirror-code .CodeMirror-matchingbracket {
  font-weight: bold;
  color: #000;
  background-color: #fef9d3; }

.gd-input-syntax-highlighting-input .CodeMirror-code .CodeMirror-nonmatchingbracket {
  color: #e54d42; }

.gd-input-syntax-highlighting-input .CodeMirror-code .cm-variable-brackets {
  color: #94a1ad; }

.gd-input-syntax-highlighting-input .CodeMirror-code .cm-variable-4 {
  font-weight: bold;
  color: #13b1e2; }

.gd-input-syntax-highlighting-input .CodeMirror-code .cm-variable-5 {
  font-weight: bold;
  color: #00c18e; }

.gd-input-syntax-highlighting-input .CodeMirror-code .cm-keyword {
  font-weight: bold;
  color: #ab55a3; }

.gd-numeric-input {
  display: flex; }

/* hide IE10+ eraser */
::-ms-clear {
  display: none; }
